import { ReactComponent as LogoIcon } from '@assets/icons/logo-no-text.svg';
import Button from '@components/V4/Button';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { useAuthState } from '@shared/react';
import { storage } from '@shared/react/UniversalStorage';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';

type Props = {
  setIsWaitingRoom: (isWaitingRoom: boolean) => void;
};

const VideoMeetingDetails = (props: Props) => {
  const { setIsWaitingRoom } = props;

  const { isMentor, userId } = useAuthState();

  const {
    meetingDetails,
    zoomClient,
    canJoinMeeting,
    webSocket,
    isSocketConnected,
    setIsMeetingLoading,
  } = useContext(ZoomContext);

  const location = useLocation();
  const history = useHistory();

  const { meetingId } = useParams<{ meetingId: string }>();

  const { name, startDate, agenda, googleDrive } = meetingDetails || {};

  useEffect(() => {
    //TODO remove this
    const token = storage.getItem('auth0token');

    if (!webSocket || !isSocketConnected || !token || !userId) return;

    webSocket.send(
      JSON.stringify({
        type: 'join-waiting-room',
        meetingId,
        token: `Bearer ${token}`,
      })
    );

    return () => {
      webSocket.send(
        JSON.stringify({
          type: 'leave-waiting-room',
          meetingId,
          token: `Bearer ${token}`,
        })
      );
    };
  }, [isSocketConnected, userId]);

  const handleJoinMeeting = async () => {
    if (!meetingDetails) return;

    setIsWaitingRoom(false);
    setIsMeetingLoading(true);

    await zoomClient?.join(
      meetingDetails.accessTokenData.session_name,
      meetingDetails.accessToken,
      meetingDetails.accessTokenData.name
    );

    const params = new URLSearchParams(location.search);
    params.append('inMeeting', 'true');

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });

    setIsMeetingLoading(false);
  };

  return (
    <div
      className="
          flex flex-col gap-6 items-center px-4 pb-6
          tablet:px-0 tablet:order-1
          laptop:items-start laptop:w-1/2 laptop:min-w-1/2 laptop:max-w-[calc(50%-clamp(4rem,7.2vw,6.5rem)/2)]
          largeLaptop:max-w-[29rem]
        "
    >
      <LogoIcon />

      <h1 className="font-raleway text-xl font-bold">{name}</h1>

      <p className="text-xs">
        Date & Time: {moment(startDate).format('DD MMM YYYY, HH:mm')}
      </p>

      <p className="text-xs text-center leading-4 laptop:text-left">{agenda}</p>

      <div className="flex justify-between gap-4">
        <Button
          disabled={!canJoinMeeting}
          variant="primary"
          buttonText={`${isMentor ? 'Start' : 'Join'} Meeting`}
          className="h-8"
          onClick={handleJoinMeeting}
        />
        <a
          href={`https://drive.google.com/drive/folders/${googleDrive}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button variant="secondary" buttonText="Open Drive" className="h-8" />
        </a>
        <Link to={''} target="_blank">
          <Button
            variant="secondary"
            buttonText="View Homework"
            className="h-8"
          />
        </Link>
      </div>
    </div>
  );
};

export default VideoMeetingDetails;
