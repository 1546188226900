import Modal from '@components/V4/Modal';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

import authHeader from '@services/auth-header';
import {
  CloseModalProps,
  Enrollment,
  ReportStatus,
  Session,
} from '@shared/common';

import { isProduction } from '@shared/env';
import { BASE_URL } from '@shared/frontendEnv';
import { message, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import AgoraSpinner from './V3/Utils/AgoraSpinner';
import Button from './V4/Button';
const { Title } = Typography;

type UserArg = { _id: string; fullName: string };

type Props = CloseModalProps & {
  name?: string;
  users?: UserArg[];
  enrollmentId?: string;
  defaultEnrollment?: Enrollment;
  defaultStudent?: {
    fullName?: string;
    id: string;
  };
  defaultSession?: Session;
  postSuccess?: () => void;
};

type ElectronicModalProps = {
  reportId?: string;
  invoiceNo: string;
  status: number;
  handleRowTouched: () => void;
  nextReport: () => void;
  prevReport: () => void;
  isFirst: boolean;
  isLast: boolean;
  eInvoiceRejectionReason?: string;
};

const ElectronicInvoiceModal = (props: Props & ElectronicModalProps) => {
  const {
    visible,
    hide,
    reportId,
    invoiceNo,
    status,
    handleRowTouched,
    nextReport,
    prevReport,
    isFirst,
    isLast,
    eInvoiceRejectionReason,
  } = props;
  const [invoice, setInvoice] = useState<any>();

  const [showReasonInput, setShowReasonInput] = useState(false);
  const [reason, setReason] = useState('');

  const [isDisabled, setIsDisabled] = useState(!!invoice);

  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(true);

  const handleAccept = async () => {
    try {
      await axios.patch(
        `${BASE_URL}/api/reports/e-invoice-status-handler`,
        { reportId, status: 'accepted' },
        { headers: authHeader() }
      );
      message.success('E-Invoice accepted successfully');
      handleRowTouched();
      if (isLast) {
        hide();
      } else {
        nextReport();
      }
    } catch (error: any) {
      message.error('Invoice could not be accepted');
    }
  };

  const handleReject = async () => {
    try {
      await axios.patch(
        `${BASE_URL}/api/reports/e-invoice-status-handler`,
        { reportId, status: 'rejected', reason },
        { headers: authHeader() }
      );
      message.success('E-Invoice rejected successfully');
      setReason('');
      setShowReasonInput(false);
      handleRowTouched();
      if (isLast) {
        hide();
      } else {
        nextReport();
      }
    } catch (error: any) {
      message.error('Invoice could not be rejected');
    }
  };

  const saveByteArrayFile = (blob: any) => {
    const url = window.URL.createObjectURL(
      new Blob([blob.data], { type: 'application/pdf' })
    );
    setInvoice(url);
  };

  const handleDownloadInvoice = async (reportId: string) => {
    setIsInvoiceDownloading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/report/${reportId}/download-invoice`,
        { headers: authHeader(), responseType: 'blob' }
      );
      saveByteArrayFile(response);
    } catch (error: any) {
      setInvoice(undefined);
      if (isProduction) {
        message.error(`Error: ${(error?.response.message, error?.message)}`);
      }
    } finally {
      setIsInvoiceDownloading(false);
    }
  };

  useEffect(() => {
    if (reportId) {
      handleDownloadInvoice(reportId);
    }
  }, [reportId]);

  useEffect(() => {
    setIsDisabled(!invoice);
  }, [invoice]);

  const modalContent = () => {
    if (isInvoiceDownloading) {
      return (
        <span className="flex justify-center items-center w-full h-full">
          <AgoraSpinner fontSize={100} useBlack={false} />
        </span>
      );
    }
    if (invoice)
      return (
        <embed src={invoice} className="w-full h-full" type="application/pdf" />
      );
    return (
      <div className="w-full h-full">
        <Title level={2} className="text-white">
          No invoice to display.
        </Title>
      </div>
    );
  };

  const modalActionButtons = () => {
    if (eInvoiceRejectionReason && status === ReportStatus.INVALID_E_INVOICE) {
      return (
        <span className="px-12 text-error">
          Rejected for: {eInvoiceRejectionReason}
        </span>
      );
    }

    if (!showReasonInput) {
      return (
        <>
          <Button
            buttonText="Reject e-Invoice"
            size="large"
            className="bg-customRed text-surfaceObject hover:bg-customRedAccent hover:text-surfaceObject"
            onClick={() => {
              setShowReasonInput(true);
            }}
            disabled={isDisabled || isInvoiceDownloading}
          ></Button>
          <Button
            buttonText="Accept e-Invoice"
            className="ml-4"
            size="large"
            onClick={() => {
              handleAccept();
            }}
            disabled={isDisabled || isInvoiceDownloading}
          ></Button>
        </>
      );
    }

    return (
      <div className="flex items-center">
        <TextArea
          placeholder="Reason for rejection..."
          className="w-1/2 h-8"
          onChange={(e) => setReason(e.target.value)}
        />
        <Button
          buttonText="Submit"
          variant="secondary"
          className="mr-4 ml-4"
          onClick={handleReject}
        ></Button>
        <Button
          buttonText="Cancel"
          variant="secondary"
          onClick={() => setShowReasonInput(false)}
        ></Button>
      </div>
    );
  };

  return (
    <Modal isOpen={visible} onClose={hide} size="fullScreen" hasFullMaxHeight>
      <Modal.Header title="Verify e-Invoice" className="mb-6" />
      <Modal.Body className="justify-center items-center">
        <div className="flex w-full h-full">{modalContent()}</div>
        <div className="flex justify-start text-white mt-4">
          Invoice Number: {invoiceNo}
        </div>
      </Modal.Body>
      <Modal.Footer className="mt-4">
        <div className="flex justify-between">
          <Button
            buttonText="Previous"
            variant="secondary"
            disabled={isFirst}
            onClick={() => {
              setReason('');
              setShowReasonInput(false);
              prevReport();
            }}
            icon={<ChevronLeftIcon width={18} />}
          />

          <div className="flex">{modalActionButtons()}</div>

          <Button
            buttonText="Next"
            variant="secondary"
            className="flex-row-reverse"
            disabled={isLast}
            onClick={() => {
              setReason('');
              setShowReasonInput(false);
              nextReport();
            }}
            icon={<ChevronRightIcon width={18} />}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ElectronicInvoiceModal;
