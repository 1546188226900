import { ReactComponent as AudioSettingsIcon } from '@assets/icons/video-icons/audio-settings.svg';
import { ReactComponent as ExpandArrowIcon } from '@assets/icons/video-icons/expand-arrow.svg';
import { ReactComponent as TrashIcon } from '@assets/icons/video-icons/trash-icon.svg';
import { ReactComponent as VideoSettingsIcon } from '@assets/icons/video-icons/video-settings.svg';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import Modal, { ModalProps } from '@components/V4/Modal';
import ToggleButton from '@components/V4/ToggleButton';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import {
  useGetVirtualBackgrounds,
  useAddVirtualBackground,
  useDeleteVirtualBackground,
} from '@shared/react';
import { MediaDevice } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useState, ChangeEvent, useRef, useEffect } from 'react';
import MicrophoneLevelIcon from './MicrophoneLevelIcon';

interface SettingsModalProps extends ModalProps {
  micList: MediaDevice[];
  cameraList: MediaDevice[];
}

const SettingsModal = (props: SettingsModalProps) => {
  const { isOpen, micList, cameraList, onClose } = props;

  const {
    zoomClient,
    activeCamera,
    activeMicrophone,
    stream,
    setActiveCamera,
    setActiveMicrophone,
  } = useContext(ZoomContext);

  const [isAudioSectionCollapsed, setIsAudioSectionCollapsed] = useState(false);
  const [isVideoSectionCollapsed, setIsVideoSectionCollapsed] = useState(true);
  const [backgroundSuppression, setBackgroundSuppression] = useState(true);
  const [virtualBackground, setVirtualBackground] = useState<
    string | undefined
  >('blur');

  const videoPreviewRef = useRef<HTMLCanvasElement>(null);
  const isChangingVirtualBackground = useRef(false);
  const isChangingNoiseSuppression = useRef(false);

  const { data: virtualBackgrounds } = useGetVirtualBackgrounds();

  const {
    mutate: addVirtualBackground,
    isLoading: isUpdatingVirtualBackgrounds,
  } = useAddVirtualBackground();

  const {
    mutate: deleteVirtualBackground,
    isLoading: isDeletingVirtualBackgrounds,
  } = useDeleteVirtualBackground();

  const [showToast] = useToast({ duration: 'infinite' });

  useEffect(() => {
    if (!stream || !zoomClient || !videoPreviewRef.current) return;

    stream
      .previewVirtualBackground(videoPreviewRef.current, '', true, activeCamera)
      .catch((error) => {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.type,
        });
      });

    return () => {
      stream.stopPreviewVirtualBackground();
    };
  }, []);

  const fileChangeHandler = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!stream || !event.target.files) return;

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);

    addVirtualBackground(formData, {
      onSuccess: (url) => virtualBackgroundChangeHandler(url),
    });
  };

  const deleteVirtualBackgroundHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    background: string
  ) => {
    e.stopPropagation();

    if (virtualBackground === background) {
      virtualBackgroundChangeHandler(undefined);
    }

    deleteVirtualBackground(
      { background },
      {
        onError: (error: any) => {
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: error.type,
          });
        },
      }
    );
  };

  const cameraChangeHandler = async (value: string) => {
    if (!stream || !videoPreviewRef.current || value === activeCamera) return;

    setActiveCamera(value);

    try {
      await stream.switchCamera(value);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }
  };

  const microphoneChangeHandler = async (value: string) => {
    if (!stream || value === activeMicrophone) return;

    setActiveMicrophone(value);

    try {
      await stream?.switchMicrophone(value);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }
  };

  const virtualBackgroundChangeHandler = async (background?: string) => {
    if (!stream || isChangingVirtualBackground.current === true) return;

    isChangingVirtualBackground.current = true;

    const newVirtualBackground =
      background === virtualBackground ? undefined : background;

    try {
      await stream.updateVirtualBackgroundImage(newVirtualBackground);

      setVirtualBackground(newVirtualBackground);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }

    isChangingVirtualBackground.current = false;
  };

  const backgroundNoiseSuppressionHandler = async () => {
    if (!stream || isChangingNoiseSuppression.current) return;

    isChangingNoiseSuppression.current = true;

    await stream.enableBackgroundNoiseSuppression(!backgroundSuppression);
    setBackgroundSuppression((suppression) => !suppression);

    isChangingNoiseSuppression.current = false;
  };

  return (
    <Modal
      className="h-full max-h-189"
      isOpen={isOpen}
      onClose={onClose}
      hasFullMaxHeight
    >
      <Modal.Header title="Settings" />
      <Modal.Body className="overflow-x-hidden overflow-y-auto h-full">
        {/* Audio Section */}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col overflow-hidden">
            <div className="flex justify-between">
              <div className="flex items-center gap-5">
                <AudioSettingsIcon />
                <h3 className="text-base font-bold">Audio Settings</h3>
              </div>
              <IconButton
                icon={
                  <ExpandArrowIcon
                    className={mergeClassNames(
                      'transition-all ease-in-out duration-300',
                      {
                        'rotate-180': !isAudioSectionCollapsed,
                      }
                    )}
                  />
                }
                variant="ghost"
                onClick={() =>
                  setIsAudioSectionCollapsed((collapsed) => !collapsed)
                }
              />
            </div>
            <div
              className={mergeClassNames(
                'flex flex-col transition-all ease-in-out duration-300 w-full gap-6',
                {
                  'max-h-0 my-0 opacity-0': isAudioSectionCollapsed,
                  'max-h-[80px] laptop:max-h-[140px] my-6 opacity-100':
                    !isAudioSectionCollapsed,
                }
              )}
            >
              <div className="hidden justify-between items-center w-full laptop:flex">
                <div className="flex flex-col gap-4 w-full">
                  <h6 className="text-xs font-bold">Audio Device</h6>
                  <AgoraBigSelector
                    options={micList}
                    valueKey="deviceId"
                    labelKey="label"
                    value={activeMicrophone}
                    onSelect={(value) =>
                      microphoneChangeHandler(value as string)
                    }
                    className="max-w-1/2"
                    allowClear={false}
                    isDisabled={!micList.length}
                  />
                </div>
                <MicrophoneLevelIcon activeMicrophone={activeMicrophone} />
              </div>
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-col gap-4 w-full">
                  <h6 className="text-xs font-bold">
                    Background Noise Surpression
                  </h6>
                  <p className="text-xs">
                    Mutes Background Noise for better voice clarity
                  </p>
                </div>
                <ToggleButton
                  isChecked={backgroundSuppression}
                  onClick={backgroundNoiseSuppressionHandler}
                />
              </div>
            </div>
          </div>

          {/* Divider */}
          <div className="h-px w-full bg-white" />

          {/* Video Section */}
          <div className="flex flex-col overflow-hidden">
            <div className="flex justify-between">
              <div className="flex items-center gap-5">
                <VideoSettingsIcon />
                <h3 className="text-base font-bold">Video Settings</h3>
              </div>
              <IconButton
                icon={
                  <ExpandArrowIcon
                    className={mergeClassNames(
                      'transition-all ease-in-out duration-300',
                      {
                        'rotate-180': !isVideoSectionCollapsed,
                      }
                    )}
                  />
                }
                variant="ghost"
                onClick={() =>
                  setIsVideoSectionCollapsed((collapsed) => !collapsed)
                }
              />
            </div>
            <div
              className={mergeClassNames(
                'flex flex-col transition-all ease-in-out duration-300 h-auto w-full gap-6',
                {
                  'max-h-0 my-0 opacity-0': isVideoSectionCollapsed,
                  'max-h-[560px] laptop:max-h-[647px] my-6 opacity-100':
                    !isVideoSectionCollapsed,
                }
              )}
            >
              <canvas
                className="mx-auto w-full max-w-[500px]"
                ref={videoPreviewRef}
              />

              <div className="hidden flex-col gap-4 w-full laptop:flex">
                <h6 className="text-xs font-bold">Video Device</h6>
                <AgoraBigSelector
                  options={cameraList}
                  valueKey="deviceId"
                  labelKey="label"
                  value={activeCamera}
                  onSelect={(value) => cameraChangeHandler(value as string)}
                  className="w-full"
                  allowClear={false}
                  isDisabled={!micList.length}
                />
              </div>
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-col gap-4 w-full">
                  <h6 className="text-xs font-bold">Blur Background</h6>
                  <p className="text-xs">Blur Video Background</p>
                </div>
                <ToggleButton
                  isChecked={virtualBackground === 'blur'}
                  onClick={() => virtualBackgroundChangeHandler('blur')}
                />
              </div>
              <div className="flex justify-between gap-4 items-center w-full">
                <div className="flex flex-col gap-4 w-full">
                  <h6 className="text-xs font-bold">Virtual Background</h6>
                  <p className="text-xs">
                    Upload a Virtual Background from your computer
                  </p>
                </div>
                <div className="relative cursor-pointer">
                  <input
                    disabled={isUpdatingVirtualBackgrounds}
                    type="file"
                    className="opacity-0 absolute top-0 left-0 w-full h-full"
                    onChange={fileChangeHandler}
                  />
                  <Button
                    isLoading={isUpdatingVirtualBackgrounds}
                    buttonText="Upload Background"
                  />
                </div>
              </div>
              {!!virtualBackgrounds?.length && (
                <div className="flex items-center gap-2 max-w-full overflow-x-auto overflow-y-hidden py-2.5">
                  {virtualBackgrounds.toReversed().map((url) => (
                    <div
                      key={url}
                      className={mergeClassNames(
                        'group relative rounded aspect-video h-[100px] w-auto border-solid border-2 border-transparent bg-cover bg-center bg-no-repeat cursor-pointer',
                        {
                          'border-customPrimary': virtualBackground === url,
                        }
                      )}
                      style={{ backgroundImage: `url(${url})` }}
                      onClick={() => virtualBackgroundChangeHandler(url)}
                    >
                      <IconButton
                        isDisabled={isDeletingVirtualBackgrounds}
                        onClick={(e) => deleteVirtualBackgroundHandler(e, url)}
                        className="absolute top-2 right-2 hidden opacity-0 text-customRed group-hover:flex group-hover:opacity-100"
                        icon={<TrashIcon />}
                        variant="ghost"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SettingsModal;
