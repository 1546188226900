import RaiseHandSound from '@assets/sounds/raise-hand.wav';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { PageNameType } from '@modules/MeetingVideo/types/PageNameType';

import {
  CommandChannelMsg,
  LiveTranscriptionClient,
  MediaDevice,
  Participant,
  RecordingClient,
  Stream,
  VideoPlayer as VideoPlayerType,
  VideoQuality,
} from '@zoom/videosdk';
import useHideHubspot from 'apps/agora/src/hooks/useHideHubspot';
import { usePrevious } from 'apps/agora/src/hooks/usePrevious';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useEffect, useRef, useState } from 'react';
import { findRemovedDevicesById, mountDevices } from '../../utils/helpers';
import MeetingActionButtons from './MeetingActionButtons';
import ThumbsUpLottie from './ThumbsUpLottie';
import VideoActionButtons from './VideoActionButtons';
import VideoMeetingLoading from './VideoMeetingLoading';
import EndMeetingModal from './VideoMeetingModals/EndMeetingModal';
import SettingsModal from './VideoMeetingModals/SettingsModal/SettingsModal';
import VideoPlayer from './VideoPlayer';
import VideoSidebar from './VideoSidebar/VideoSidebar';
import { findParticipantsDifference } from './helpers';
import { storage } from '@shared/react/UniversalStorage';
import { useParams } from 'react-router-dom';
import { useAuthState } from '@shared/react';

const VideoMeeting = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [pageName, setPageName] = useState<PageNameType | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [activeModal, setActiveModal] = useState<'end-meeting' | 'settings'>();
  const [activeButton, setActiveButton] = useState<PageNameType | undefined>(
    undefined
  );
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [speakingParticipants, setSpeakingParticipants] = useState<
    Record<number, boolean>
  >({});
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [raisedHands, setRaisedHands] = useState<Record<number, boolean>>({});
  const [thumbsUpList, setThumbsUpList] = useState<
    { senderId: number; senderName: string; timestamp: number }[]
  >([]);
  const [isViewingScreenShare, setIsViewingScreenShare] = useState(false);

  const pastParticipants = usePrevious(participants);
  const videoListRef = useRef<Record<string, VideoPlayerType>>({});
  const screenShareVideoContainerRef = useRef<HTMLVideoElement>(null);
  const screenShareCanvasContainerRef = useRef<HTMLCanvasElement>(null);
  const isCameraStateSwitchLoading = useRef(false);
  const isMicrophoneStateSwitchLoading = useRef(false);
  const timeoutRefs = useRef<Record<string, NodeJS.Timeout>>({});

  const [showToast] = useToast({ duration: 'infinite' });

  const { meetingId } = useParams<{ meetingId: string }>();

  const { isMentor, userId } = useAuthState();

  const {
    isMeetingLoading,
    meetingDetails,
    zoomClient,
    activeCamera,
    activeMicrophone,
    stream,
    isCameraActive,
    isMicrophoneActive,
    webSocket,
    isSocketConnected,
    setStream,
    setIsCameraActive,
    setIsMicrophoneActive,
    setActiveMicrophone,
    setActiveCamera,
  } = useContext(ZoomContext);

  useHideHubspot();

  useEffect(() => {
    //TODO remove this
    const token = storage.getItem('auth0token');

    if (
      !webSocket ||
      !isSocketConnected ||
      !token ||
      !userId ||
      isMeetingLoading
    )
      return;

    if (webSocket) {
      webSocket.send(
        JSON.stringify({
          type: 'join-meeting',
          meetingId,
          token: `Bearer ${token}`,
        })
      );
    }
  }, [isSocketConnected, userId, isMeetingLoading]);

  const startVideo = async (stream: typeof Stream) => {
    if (!stream) return;

    try {
      await stream.startVideo({
        cameraId: activeCamera,
        virtualBackground: { imageUrl: 'blur' },
      });
    } catch (error: any) {
      switch (error.type) {
        case 'CAN_NOT_DETECT_CAMERA':
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: 'Cannot detect camera device.',
          });
          break;
        case 'CAN_NOT_FIND_CAMERA':
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody:
              'The provided camera device ID is not included in the camera device list.',
          });
          break;
        case 'VIDEO_USER_FORBIDDEN_CAPTURE':
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody:
              'You have forbidden the use of the camera or the camera is taken by other programs.',
          });
          break;
        case 'VIDEO_ESTABLISH_STREAM_ERROR':
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: 'Video WebSocket is broken.',
          });
          break;
        case 'VIDEO_CAMERA_IS_TAKEN':
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody:
              "Please check if another app is using your camera, then close it, followed by a refresh, if you'd like to be seen.",
          });
          break;
        default:
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: error.type,
          });
      }

      throw new Error('Could not start video.');
    }
  };

  useEffect(() => {
    mountDevices().then(({ microphones, cameras }) => {
      setMicList(microphones);
      setCameraList(cameras);
    });
  }, []);

  useEffect(() => {
    if (isMeetingLoading || !zoomClient) {
      return;
    }

    setIsCameraActive(false);
    setIsMicrophoneActive(false);

    isCameraStateSwitchLoading.current = true;
    isMicrophoneStateSwitchLoading.current = true;

    let cloudRecording: typeof RecordingClient;
    let transcriptionClient: typeof LiveTranscriptionClient;

    (async () => {
      try {
        await zoomClient.init('en-US', 'Global', {
          patchJsMedia: true,
          stayAwake: true,
          enforceMultipleVideos: true,
        });
      } catch (error) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Could not initalize zoom client',
        });
        return;
      }

      const stream = zoomClient.getMediaStream();
      setStream(stream);

      if (isCameraActive) {
        try {
          await startVideo(stream);
          setIsCameraActive(true);
        } catch (error) {
          setIsCameraActive(false);
        }
      }

      try {
        await stream.startAudio({
          microphoneId: activeMicrophone,
          backgroundNoiseSuppression: true,
        });
        setIsMicrophoneActive(true);

        if (!isMicrophoneActive) {
          await stream.muteAudio();
        }
      } catch (error: any) {
        setIsMicrophoneActive(false);
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.type,
        });
      }

      zoomClient.getAllUser().forEach((user) => {
        if (user.sharerOn && !!screenShareCanvasContainerRef.current) {
          stream
            .startShareView(screenShareCanvasContainerRef.current, user.userId)
            .then(() => setIsViewingScreenShare(true))
            .catch(() =>
              showToast({
                variant: 'error',
                messageTitle: 'Error',
                messageBody: 'Could not start viewing the screen share.',
              })
            );
        }
      });

      if (meetingDetails?.accessTokenData.type === 1) {
        cloudRecording = zoomClient.getRecordingClient();

        try {
          await cloudRecording.startCloudRecording();
        } catch (error) {
          showToast({
            variant: 'error',
            messageTitle: 'Error',
            messageBody: 'Could not start recording.',
          });
        }
      }

      transcriptionClient = zoomClient.getLiveTranscriptionClient();

      try {
        await transcriptionClient?.startLiveTranscription();
      } catch (error) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: 'Could not start live transcription.',
        });
      }

      isMicrophoneStateSwitchLoading.current = false;
      isCameraStateSwitchLoading.current = false;

      const participants = zoomClient.getAllUser();

      setParticipants(participants);
    })();

    return () => {
      (async () => {
        try {
          await transcriptionClient?.disableCaptions(true);
        } catch (error) {
          console.log(error);
        }

        try {
          await zoomClient?.leave();
        } catch (error) {
          console.log(error);
        }

        Object.values(timeoutRefs.current).forEach((timeoutId) => {
          clearTimeout(timeoutId);
        });
      })();
    };
  }, [isMeetingLoading]);

  useEffect(() => {
    if (!stream || !zoomClient) return;

    const updateVideos = () => {
      const participants = zoomClient.getAllUser();

      setParticipants(participants);
    };

    const userAddedHandler = () => {
      updateVideos();
    };
    const userUpdatedHandler = () => {
      updateVideos();
    };
    const userRemovedHandler = () => {
      updateVideos();
    };

    const activeSpeakerHandler = (speakingUsers: { userId: number }[]) => {
      const updatedSpeakingParticipants = { ...speakingParticipants };

      for (const speakingUser of speakingUsers) {
        const userId = speakingUser.userId;

        if (timeoutRefs.current[userId]) {
          clearTimeout(timeoutRefs.current[userId]);
        }

        updatedSpeakingParticipants[userId] = true;

        timeoutRefs.current[userId] = setTimeout(() => {
          setSpeakingParticipants((prevParticipants) => ({
            ...prevParticipants,
            [userId]: false,
          }));
        }, 1000);
      }

      setSpeakingParticipants(updatedSpeakingParticipants);
    };

    const commandChannelHandler = (payload: CommandChannelMsg) => {
      switch (payload.text) {
        case 'hand-raise':
          raiseHandClickHandler(payload.senderId);
          break;

        case 'thumbs-up':
          thumbsUpClickHandler(
            payload.senderId,
            payload?.senderName || '',
            payload.timestamp
          );
          break;
      }
    };

    const shareScreenHandler = async (payload: {
      state: 'Active' | 'Inactive';
      userId: number;
    }) => {
      if (!screenShareCanvasContainerRef.current) return;

      if (payload.state === 'Active') {
        await stream.startShareView(
          screenShareCanvasContainerRef.current,
          payload.userId
        );

        setIsViewingScreenShare(true);
      } else if (payload.state === 'Inactive') {
        await stream.stopShareView();
        setIsViewingScreenShare(false);
      }
    };

    zoomClient.on('user-added', userAddedHandler);
    zoomClient.on('user-updated', userUpdatedHandler);
    zoomClient.on('user-removed', userRemovedHandler);
    zoomClient.on('active-speaker', activeSpeakerHandler);
    zoomClient.on(`command-channel-message`, commandChannelHandler);
    zoomClient.on('active-share-change', shareScreenHandler);

    return () => {
      zoomClient.off('user-added', userAddedHandler);
      zoomClient.off('user-updated', userUpdatedHandler);
      zoomClient.off('user-removed', userRemovedHandler);
      zoomClient.off('active-speaker', activeSpeakerHandler);
      zoomClient.off(`command-channel-message`, commandChannelHandler);
      zoomClient.off(`active-share-change`, shareScreenHandler);

      stream.stopAudio().then(console.log).catch(console.log);
      stream
        .stopVideo()
        .then(() => {
          stream
            ?.detachVideo(zoomClient?.getSessionInfo().userId)
            .then(console.log)
            .catch(console.log);
        })
        .catch(console.log);
    };
  }, [stream]);

  useEffect(() => {
    if (!zoomClient || !stream) return;

    const deviceChangeHandler = async () => {
      const cameras = stream.getCameraList();
      const microphones = stream.getMicList();

      if (microphones.length !== micList.length) {
        if (microphones.length < micList.length) {
          const removedMicrophone = findRemovedDevicesById(
            micList,
            microphones
          );
          if (activeMicrophone === removedMicrophone) {
            try {
              await stream.switchMicrophone(microphones?.[0].deviceId);
            } catch (error: any) {
              showToast({
                variant: 'error',
                messageTitle: 'Error',
                messageBody: error.type,
              });
            }
            setActiveMicrophone(microphones?.[0].deviceId);
          }
        }
        setMicList(microphones);
      }

      if (cameras.length !== cameraList.length) {
        if (cameras.length < cameraList.length) {
          const removedCamera = findRemovedDevicesById(cameraList, cameras);

          if (activeCamera === removedCamera) {
            try {
              await stream.switchCamera(cameras?.[0].deviceId);
            } catch (error: any) {
              showToast({
                variant: 'error',
                messageTitle: 'Error',
                messageBody: error.type,
              });
            }
            setActiveCamera(cameras?.[0].deviceId);
          }
        }
        setCameraList(cameras);
      }
    };

    zoomClient.on('device-change', deviceChangeHandler);

    return () => {
      zoomClient.off('device-change', deviceChangeHandler);
    };
  }, [stream, cameraList, micList, activeCamera, activeMicrophone]);

  useEffect(() => {
    if (
      !stream ||
      !pastParticipants ||
      (!pastParticipants.length && !participants.length)
    )
      return;

    const { addedParticipants, removedParticipants } =
      findParticipantsDifference(
        pastParticipants.filter((participant) => participant.bVideoOn),
        participants.filter((participant) => participant.bVideoOn)
      );

    addedParticipants.forEach((participant) => {
      stream.attachVideo(
        participant.userId,
        VideoQuality.Video_720P,
        videoListRef.current[`${participant.userId}`]
      );
    });

    removedParticipants.forEach((participant) => {
      stream.detachVideo(participant.userId);
    });
  }, [stream, participants]);

  const handleShowSidebar = (pageName: PageNameType) => {
    setShowSidebar(true);
    setPageName(pageName);
  };

  const handleCloseSidebar = () => {
    setShowSidebar(false);
    setPageName(undefined);
    setActiveButton(undefined);
  };

  const closeModalHandler = () => {
    setShowModal(false);
    setActiveModal(undefined);
  };

  const displayModalByActiveModal = () => {
    switch (activeModal) {
      case 'end-meeting':
        return (
          <EndMeetingModal isOpen={showModal} onClose={closeModalHandler} />
        );
      case 'settings':
        return (
          <SettingsModal
            isOpen={showModal}
            onClose={closeModalHandler}
            micList={micList}
            cameraList={cameraList}
          />
        );
      default:
        return null;
    }
  };

  const handleCameraButtonClick = async () => {
    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!stream || !myUserId || !!isCameraStateSwitchLoading.current) {
      return;
    }

    isCameraStateSwitchLoading.current = true;

    if (stream.isCapturingVideo()) {
      try {
        await stream.stopVideo();
        await stream.detachVideo(myUserId);
        setIsCameraActive(false);
      } catch (error: any) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.message,
        });
      }
      isCameraStateSwitchLoading.current = false;

      return;
    }

    try {
      await startVideo(stream);
      setIsCameraActive(true);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.message,
      });
    }

    isCameraStateSwitchLoading.current = false;
  };

  const raiseHandClickHandler = (senderId: number) => {
    if (!raisedHands[senderId]) {
      const audio = new Audio(RaiseHandSound);
      audio.play();
    }
    setRaisedHands((state) => ({ ...state, [senderId]: !state[senderId] }));
  };

  const thumbsUpClickHandler = (
    senderId: number,
    senderName: string,
    timestamp: number
  ) => {
    setThumbsUpList((state) => [...state, { senderId, senderName, timestamp }]);

    timeoutRefs.current[timestamp] = setTimeout(() => {
      setThumbsUpList((state) =>
        state.filter((thumbsUp) => thumbsUp.timestamp !== timestamp)
      );
    }, 5000);
  };

  const shareScreenClickHandler = async () => {
    if (
      !stream ||
      !screenShareCanvasContainerRef.current ||
      !screenShareVideoContainerRef.current
    )
      return;

    if (isSharing) {
      try {
        await stream.stopShareScreen();
      } catch (error: any) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.type,
        });
        throw new Error(error.type);
      }
      return;
    }

    const screenShareContainer = stream?.isStartShareScreenWithVideoElement()
      ? screenShareVideoContainerRef.current
      : screenShareCanvasContainerRef.current;

    try {
      await stream.startShareScreen(screenShareContainer);
    } catch (error: any) {
      if (error.reason !== 'user deny screen share') {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.type,
        });
      }
      throw new Error(error.type);
    }
  };

  const getHasThumbsUp = (senderId: number) => {
    const index = thumbsUpList.findIndex(
      (thumbsUp) => thumbsUp.senderId === senderId
    );

    return index > -1;
  };

  const handleMicrophoneButtonClick = async () => {
    const myUserId = zoomClient?.getSessionInfo().userId;

    if (!stream || !myUserId || !!isMicrophoneStateSwitchLoading.current) {
      return;
    }

    isMicrophoneStateSwitchLoading.current = true;

    if (stream.isAudioMuted()) {
      try {
        await stream.unmuteAudio();

        setIsMicrophoneActive(true);
      } catch (error: any) {
        showToast({
          variant: 'error',
          messageTitle: 'Error',
          messageBody: error.message,
        });
      }
      isMicrophoneStateSwitchLoading.current = false;

      return;
    }

    try {
      await stream.muteAudio();
      setIsMicrophoneActive(false);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.message,
      });
    }

    isMicrophoneStateSwitchLoading.current = false;
  };

  const setVideoPlayerRef = (
    userId: number,
    element: VideoPlayerType | null
  ) => {
    if (element) {
      videoListRef.current[`${userId}`] = element;
    }
  };

  const isSharing = stream?.getShareStatus() === 'sharing';

  const getVideoPlayerClassName = (userId: number) => {
    const ownId = zoomClient?.getSessionInfo().userId;
    const isOwnVideo = userId === ownId;
    const isTwoWayConversation = participants.length === 2;

    if (isSharing || isViewingScreenShare) {
      return 'w-auto laptop:h-[120px]';
    }

    if (!isTwoWayConversation) {
      return '';
    }

    return {
      'h-auto': true,
      'laptop:ml-auto laptop:absolute laptop:-bottom-8 laptop:right-6 laptop:h-28 laptop:w-50 laptop:z-50 laptop:w-auto laptop:m-auto':
        isOwnVideo && isTwoWayConversation,
    };
  };

  const getVideoSize = (userId: number) => {
    const ownId = zoomClient?.getSessionInfo().userId;
    const isOwnVideo = userId === ownId;
    const isTwoWayConversation = participants.length === 2;

    if (
      (isTwoWayConversation && isOwnVideo) ||
      isSharing ||
      isViewingScreenShare
    ) {
      return 'small';
    }

    return 'default';
  };

  return isMeetingLoading ? (
    <VideoMeetingLoading />
  ) : (
    <section className="flex flex-col justify-between h-[calc(100vh-72px)] px-3 laptop:h-screen laptop:p-9">
      <div className="flex items-center justify-center flex-auto gap-4 relative h-[calc(100%-48px)] laptop:h-[calc(100%-72px)]">
        <div className="flex flex-col w-full h-full items-center justify-center gap-4">
          <video
            ref={screenShareVideoContainerRef}
            className={mergeClassNames(
              'hidden max-h-full w-full max-w-max laptop:max-h-[calc(100%-136px)] rounded-lg',
              {
                flex:
                  isSharing && !!stream?.isStartShareScreenWithVideoElement(),
              }
            )}
          />
          <canvas
            ref={screenShareCanvasContainerRef}
            className={mergeClassNames(
              'hidden max-h-full w-full max-w-max laptop:max-h-[calc(100%-136px)] rounded-lg',
              {
                flex:
                  (isSharing &&
                    !stream?.isStartShareScreenWithVideoElement()) ||
                  isViewingScreenShare,
              }
            )}
          />

          <div
            className={mergeClassNames(
              'relative flex flex-col-reverse gap-2 items-center justify-center w-full laptop:w-full laptop:flex-row short:flex-row short:max-w-[85%]',
              {
                'laptop:aspect-video laptop:h-auto laptop:max-w-[137vh]':
                  participants.length === 2 &&
                  !(isSharing || isViewingScreenShare),
                'max-w-[500px]': participants.length > 1,
                'hidden laptop:flex': isSharing || isViewingScreenShare,
              }
            )}
          >
            {participants.map((participant: Participant) => {
              return (
                <VideoPlayer
                  className={mergeClassNames(
                    getVideoPlayerClassName(participant.userId)
                  )}
                  size={getVideoSize(participant.userId)}
                  displayName={participant.displayName}
                  isCameraActive={participant.bVideoOn}
                  isMicrophoneActive={!participant.muted}
                  isSpeaking={speakingParticipants[participant.userId]}
                  hasRaisedHand={raisedHands[participant.userId]}
                  hasThumbsUp={getHasThumbsUp(participant.userId)}
                  key={participant.userId}
                  ref={(element: VideoPlayerType) =>
                    setVideoPlayerRef(participant.userId, element)
                  }
                />
              );
            })}
          </div>
        </div>

        {/* mobile only meeting actions */}
        <MeetingActionButtons
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          handleShowSidebar={handleShowSidebar}
          handleCloseSidebar={handleCloseSidebar}
          className={mergeClassNames(
            'flex flex-row tablet:flex-col absolute right-0 top-1 gap-2 laptop:hidden'
          )}
        />
        {showSidebar && (
          <VideoSidebar
            handleCloseSidebar={handleCloseSidebar}
            pageName={pageName ?? 'chat'}
          />
        )}

        {thumbsUpList.map((thumbsUp) => (
          <ThumbsUpLottie
            key={thumbsUp.timestamp}
            userName={thumbsUp.senderName}
          />
        ))}
      </div>

      <section className="relative flex items-center justify-center mt-4 laptop:mb-6 laptop:mt-14 laptop:justify-between">
        <h1 className="hidden absolute left-0 tablet:flex laptop:static">
          {meetingDetails?.name || ''}
        </h1>

        <VideoActionButtons
          isShareScreenDisabled={isViewingScreenShare}
          setActiveModal={setActiveModal}
          setShowModal={setShowModal}
          onCameraButtonClick={handleCameraButtonClick}
          onMicrophoneButtonClick={handleMicrophoneButtonClick}
          onRaiseHandClick={raiseHandClickHandler}
          onThumbsUpClick={thumbsUpClickHandler}
          onShareScreenClick={shareScreenClickHandler}
        />

        <MeetingActionButtons
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          handleShowSidebar={handleShowSidebar}
          handleCloseSidebar={handleCloseSidebar}
          className="hidden laptop:flex"
        />
      </section>
      {displayModalByActiveModal()}
    </section>
  );
};

export default VideoMeeting;
