import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { useAuthState } from '@shared/react';
import ZoomVideo, { MediaDevice } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { useContext, useEffect, useRef, useState } from 'react';
import { mountDevices } from '../../utils/helpers';
import VideoWidget from './VideoWidget';

let localAudio = ZoomVideo.createLocalAudioTrack();
let localVideo = ZoomVideo.createLocalVideoTrack();

const VideoMeetingPreview = () => {
  const [micList, setMicList] = useState<MediaDevice[]>([]);
  const [cameraList, setCameraList] = useState<MediaDevice[]>([]);
  const [initialSetupCompleted, setInitialSetupCompleted] = useState(false);

  const isCameraStateSwitchLoading = useRef<boolean>(true);
  const isMicrophoneStateSwitchLoading = useRef<boolean>(true);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const isMountedRef = useRef(false);
  const streamRef = useRef<MediaStream | undefined>();

  const {
    zoomClient,
    activeCamera,
    activeMicrophone,
    isMicrophoneActive,
    isCameraActive,
    setActiveCamera,
    setActiveMicrophone,
    setIsMicrophoneActive,
    setIsCameraActive,
    setCanJoinMeeting,
  } = useContext(ZoomContext);

  const { avatar } = useAuthState();

  const [showToast] = useToast({ duration: 'infinite' });

  const showPermissionsToast = () =>
    showToast({
      variant: 'error',
      messageTitle: 'Error',
      messageBody:
        "Please enable your camera and microphone permissions, followed by a refresh, if you'd like to be seen and heard.",
    });

  const showVideoErrorToast = () =>
    showToast({
      variant: 'error',
      messageTitle: 'Error',
      messageBody:
        "Please check if another app is using your camera, then close it, followed by a refresh, if you'd like to be seen.",
    });

  const stopTracks = (filter?: 'audio' | 'video') => {
    const tracks = streamRef.current
      ?.getTracks()
      .filter((track) => (filter ? track.kind === filter : true));

    if (!tracks || !tracks.length) return;

    tracks.forEach((track) => track.stop());
  };

  useEffect(() => {
    isMountedRef.current = true;

    if (!zoomClient) return;

    zoomClient
      .init('en-US', 'Global', {
        patchJsMedia: true,
        stayAwake: true,
        enforceMultipleVideos: true,
      })
      .then(() => {
        mountDevices()
          .then(async ({ microphones, cameras, stream }) => {
            streamRef.current = stream;

            localAudio = ZoomVideo.createLocalAudioTrack(
              microphones?.[0]?.deviceId
            );

            if (!isMountedRef.current) return;

            try {
              await localAudio.start();

              if (!isMountedRef.current) return;

              await localAudio.unmute();

              if (!isMountedRef.current) return;

              if (!microphones.length) {
                showToast({
                  variant: 'error',
                  messageTitle: 'Error',
                  messageBody:
                    "Please check if another app is using your microphone, then close it, followed by a refresh, if you'd like to be heard.",
                });
              }

              setIsMicrophoneActive(true);

              isMicrophoneStateSwitchLoading.current = false;
              setActiveMicrophone(microphones?.[0]?.deviceId);
              setMicList(microphones);
            } catch (error: any) {
              showToast({
                variant: 'warning',
                messageTitle: 'Error',
                messageBody: error.message,
              });
            }

            if (videoRef.current) {
              if (!isMountedRef.current) return;

              localVideo = ZoomVideo.createLocalVideoTrack(
                cameras?.[0]?.deviceId
              );

              try {
                await localVideo.start(videoRef.current);
                if (!isMountedRef.current) return;

                setIsCameraActive(true);

                isCameraStateSwitchLoading.current = false;
                setActiveCamera(cameras?.[0]?.deviceId);
                setCameraList(cameras);
              } catch (error: any) {
                if (error.message === 'Starting videoinput failed') {
                  showVideoErrorToast();
                } else if (
                  error.name === 'NotAllowedError' &&
                  isMountedRef.current
                ) {
                  showPermissionsToast();
                } else {
                  showToast({
                    variant: 'error',
                    messageTitle: 'Error',
                    messageBody: error.message,
                  });
                }
              }
            }

            if (!isMountedRef.current) return;

            setInitialSetupCompleted(true);
          })
          .finally(() => setCanJoinMeeting(true));
      });

    return () => {
      isMountedRef.current = false;

      stopTracks();

      localAudio.stop().catch(console.log);
      localVideo.stop().catch(console.log);

      ZoomVideo.destroyClient();
    };
  }, []);

  useEffect(() => {
    const deviceChangeHandler = async () => {
      if (!initialSetupCompleted) {
        return;
      }

      setCanJoinMeeting(false);
      const { microphones, cameras } = await mountDevices(streamRef.current);

      if (microphones.length !== micList.length) {
        if (microphones.length < micList.length) {
          localAudio = ZoomVideo.createLocalAudioTrack(
            microphones?.[0]?.deviceId
          );

          try {
            await localAudio.start();

            setIsMicrophoneActive(false);
            setActiveMicrophone(microphones?.[0]?.deviceId);
          } catch (error) {
            console.error('Failed to start or unmute new audio track:', error);
          }
        }
        setMicList(microphones);
      }

      if (cameras.length !== cameraList.length) {
        if (cameras.length < cameraList.length) {
          try {
            await localVideo.stop();
            stopTracks('video');
          } catch (error) {
            console.error('Failed to stop video:', error);
          }

          localVideo = ZoomVideo.createLocalVideoTrack(cameras?.[0].deviceId);

          setActiveCamera(cameras?.[0].deviceId);
          setIsCameraActive(false);
        }
        setCameraList(cameras);
      }
      setCanJoinMeeting(true);
    };

    navigator.mediaDevices.addEventListener(
      'devicechange',
      deviceChangeHandler
    );

    return () =>
      navigator.mediaDevices.removeEventListener(
        'devicechange',
        deviceChangeHandler
      );
  }, [micList, cameraList, initialSetupCompleted]);

  // Preview buttons methods
  const handleMicrophoneClick = async () => {
    if (isMicrophoneStateSwitchLoading.current) return;

    isMicrophoneStateSwitchLoading.current = true; // makes sure that the method will return if async actions are not completed

    try {
      if (isMicrophoneActive) {
        await localAudio?.mute();
      } else {
        await localAudio?.unmute();
      }

      setIsMicrophoneActive((prev) => !prev);
      isMicrophoneStateSwitchLoading.current = false;
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  const handleCameraClick = async () => {
    if (!videoRef.current || isCameraStateSwitchLoading.current) return;

    isCameraStateSwitchLoading.current = true; // makes sure that the method will return if async actions are not completed

    try {
      if (isCameraActive) {
        await localVideo?.stop();
        stopTracks('video');
      } else {
        await localVideo?.start(videoRef.current);
      }
      setIsCameraActive((prev) => !prev);

      isCameraStateSwitchLoading.current = false;
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  // Selector methods
  const handleMicrophoneChange = async (value: string) => {
    if (activeMicrophone === value) return;

    try {
      localAudio = ZoomVideo.createLocalAudioTrack(value);

      await localAudio.start();

      if (isMicrophoneActive) {
        await localAudio.unmute();
        setIsMicrophoneActive(true);
      }
      setActiveMicrophone(value);
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  const handleCameraChange = async (value: string) => {
    if (activeCamera === value) return;

    if (!isCameraActive) {
      localVideo = ZoomVideo.createLocalVideoTrack(value);
      setActiveCamera(value);
      return;
    }

    try {
      await localVideo.switchCamera(value);
      setActiveCamera(value);
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        showPermissionsToast();
      }
    }
  };

  return (
    <div className="flex flex-col gap-5 laptop:order-2 laptop:min-w-1/2 laptop:w-1/2 laptop:max-w-[calc(50%-clamp(4rem,7.2vw,6.5rem)/2)]">
      <VideoWidget
        onCameraClick={handleCameraClick}
        onMicrophoneClick={handleMicrophoneClick}
        ref={videoRef}
        isCameraActive={isCameraActive}
        isMicrophoneActive={isMicrophoneActive}
        isCameraDisabled={!cameraList.length}
        isMicrophoneDisabled={!micList.length}
        avatarUrl={avatar}
      />
      <div className="hidden flex-row gap-5 justify-between laptop:flex laptop:w-full">
        <AgoraBigSelector
          options={cameraList}
          value={activeCamera}
          valueKey="deviceId"
          labelKey="label"
          onSelect={(value) => handleCameraChange(value as string)}
          label="Video Device"
          className="w-1/2 min-w-[calc(50%-0.625rem)] max-w-[calc(50%-0.625rem)]"
          allowClear={false}
          isDisabled={!cameraList.length}
        />
        <AgoraBigSelector
          options={micList}
          valueKey="deviceId"
          labelKey="label"
          value={activeMicrophone}
          onSelect={(value) => handleMicrophoneChange(value as string)}
          label="Audio Device"
          className="w-1/2 min-w-[calc(50%-0.625rem)] max-w-[calc(50%-0.625rem)]"
          allowClear={false}
          isDisabled={!micList.length}
        />
      </div>
    </div>
  );
};

export default VideoMeetingPreview;
