import { useUpcomingMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import NextMeetingStudent from './NextMeetingStudent';

const NextMeetingsStudent: React.FC = () => {
  const { data, isLoading, isError } = useUpcomingMeetings();

  const [viewAll, setViewAll] = useState(false);

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  return (
    <div className={style.meetings}>
      {!data?.length ? (
        <Typography.Paragraph style={{ color: COLORS.TEXT_SECONDARY }}>
          No upcoming meetings
        </Typography.Paragraph>
      ) : null}

      {!viewAll && data?.length
        ? data.map((meeting, index) =>
            index < 5 ? (
              <NextMeetingStudent key={meeting._id} meeting={meeting} />
            ) : null
          )
        : null}

      {viewAll && data?.length
        ? data.map((meeting, index) => (
            <NextMeetingStudent key={meeting._id} meeting={meeting} />
          ))
        : null}

      {data && data?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NextMeetingsStudent;
