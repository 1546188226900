import {
  BookOutlined,
  TeamOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import MyAccountIcon from '@assets/icons/Exclusion_2.svg';
import MyFilesIcon from '@assets/icons/folder.svg';
import KnowledgeBasePrimary from '@assets/icons/knowledge-base-icon-primary.svg';
import KnowledgeBaseWhite from '@assets/icons/knowledge-base-icon-white.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import UELogoV3 from '@assets/icons/ue-logo-v3.svg';
import UELogo from '@assets/icons/ue-logo.svg';
import {
  ACCOUNT,
  COURSES,
  MY_STUDENTS,
  KNOWLEDGE_BASE_ASSISTANT,
  SESSION_HISTORY,
} from '@routes';
import { MENTOR_ROLE } from '@shared/constants';
import {
  useAuthActions,
  useAuthState,
  useMentorSessionNo,
} from '@shared/react';
import pageStyle from '@styles/components/reusables/page.module.scss';
import style from '@styles/views/header.module.scss';

import { Alert, Button, message, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './header-style.scss';
import BalanceInfo from '../BalanceInfo';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

type Props = {
  children: any;
};

const HeaderV3 = ({ children }: Props) => {
  const history = useHistory();
  const { userId, roles, rate } = useAuthState();
  const { logout } = useAuthActions();
  const { googleDrive, folderId } = useAuthState();
  const userRole = localStorage.getItem('userRole');
  const location = useLocation();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [currency, setCurrency] = useState('$');

  const [knowledgebaseHover, setKnowledgeBaseHover] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);

  let isMentor: any = undefined;

  if (userRole === 'student') {
    isMentor = false;
  } else if (userRole === 'mentor') {
    isMentor = true;
  } else {
    isMentor = roles.includes(MENTOR_ROLE);
  }

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const isImpersonating = () => !!localStorage.getItem('impersonate-user-id');

  useEffect(() => {
    if (isMentor) {
      switch (rate?.currency) {
        case 'USD':
          setCurrency('$');
          break;
        case 'EUR':
          setCurrency('€');
          break;
        case 'GBP':
          setCurrency('£');
          break;
        case 'R0N':
          setCurrency('RON');
          break;
        default:
          setCurrency('$');
          break;
      }
    }
  }, [rate]);

  const { data: sessions } = useMentorSessionNo(impersonateId ?? userId);

  const stopImpersonating = () => {
    localStorage.clear();

    message.loading('Stopping impersonate...', 0);

    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  };

  const balanceDetails = (
    <div className={style.tooltipContainer}>
      <div className={style.tooltipInfoText}>Estimated payment</div>
      <div>
        for {moment().startOf('month').format('DD.MM')} -{' '}
        {moment().format('DD.MM')}
      </div>
      <div className={style.tooltipBalanceValue}>
        {currency}
        {rate?.amount && sessions?.noOfSessions
          ? rate?.amount * sessions?.noOfSessions
          : 0}
      </div>
      <div className={style.tooltipInfoText}>
        {sessions?.noOfSessions}{' '}
        {sessions?.noOfSessions !== 1 ? 'Sessions' : 'Session'}
      </div>
      <Button
        onClick={() => history.push('/session-history')}
        className={style.actionButton}
      >
        Session History
      </Button>
    </div>
  );

  const closeMenuHandler = () => setMenuOpen(false);

  const params = new URLSearchParams(location.search);
  const inMeeting = params.get('inMeeting');

  const linkTarget = inMeeting === 'true' ? '_blank' : '_self';

  return (
    <div className="header-banner-container">
      <div className="header-container">
        <div className="burger-left-container"></div>

        <Link to={'/home'} target={linkTarget}>
          {/*<img*/}
          {/*  style={{ width: '40px', marginTop: '32px', marginBottom: '56px' }}*/}
          {/*  src={UELogo}*/}
          {/*  alt="logo-upgrade"*/}
          {/*/>*/}
          <div
            onClick={() => {
              isMenuOpen && closeMenuHandler();
            }}
            className="image-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              className="default-image"
              style={{
                width: '32px',
                marginTop: '32px',
                marginBottom: '56px',
                marginLeft: '4px',
              }}
              src={UELogo}
              alt="logo-upgrade"
            />
            <img
              className="hover-image"
              style={{
                width: '32px',
                marginTop: '32px',
                marginBottom: '56px',
                marginLeft: '4px',
              }}
              src={UELogoV3}
              alt="logo-upgrade"
            />
          </div>
        </Link>

        <div className="desktop-links-container">
          {/*HEADER DESKTOP LINKS HERE*/}

          {isMentor && (
            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">My Students</div>
                  <div className="tooltip-description">
                    Manage your students
                  </div>
                </div>
              }
            >
              <Link to={MY_STUDENTS} target={linkTarget}>
                <div className="circle-icon">
                  <TeamOutlined className="menu-icon" />
                </div>
              </Link>
            </Tooltip>
          )}

          <Tooltip
            zIndex={1}
            color="transparent"
            placement="right"
            title={
              <div style={{ padding: '24px' }} className="tooltip-container">
                <div className="tooltip-title">Courses</div>
                <div className="tooltip-description">
                  Explore your courses collection
                </div>
              </div>
            }
          >
            <Link to={COURSES} target={linkTarget}>
              <div className="circle-icon">
                <BookOutlined className="menu-icon" />
              </div>
            </Link>
          </Tooltip>

          <Tooltip
            zIndex={1}
            color="transparent"
            placement="right"
            title={
              <div style={{ padding: '24px' }} className="tooltip-container">
                <div className="tooltip-title">My Files</div>
                <div className="tooltip-description">
                  Access and manage your files
                </div>
              </div>
            }
          >
            <Link
              to={{
                pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
              }}
              target="_blank"
            >
              <div className="circle-icon">
                <img style={{ width: '16px' }} src={MyFilesIcon} />
              </div>
            </Link>
          </Tooltip>

          {isMentor && (
            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">Session History</div>
                  <div className="tooltip-description">
                    Track your session history
                  </div>
                </div>
              }
            >
              <Link
                onClick={closeMenuHandler}
                to={SESSION_HISTORY}
                target={linkTarget}
              >
                <div className="circle-icon">
                  <ClockCircleOutlined className="menu-icon" />
                </div>{' '}
              </Link>
            </Tooltip>
          )}

          {isMentor && (
            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">Knowledge Base Assistant</div>
                  <div className="tooltip-description">
                    Use AI to explore our existing knowledge base
                  </div>
                </div>
              }
            >
              <Link
                onMouseEnter={() => setKnowledgeBaseHover(true)}
                onMouseLeave={() => setKnowledgeBaseHover(false)}
                to={KNOWLEDGE_BASE_ASSISTANT}
                target={linkTarget}
              >
                <div className="circle-icon">
                  {/*<TeamOutlined className="menu-icon" />*/}
                  {knowledgebaseHover ? (
                    <img src={KnowledgeBasePrimary} alt="knowledge-base" />
                  ) : (
                    <img src={KnowledgeBaseWhite} alt="knowledge-base" />
                  )}
                </div>
              </Link>
            </Tooltip>
          )}

          <div className="icon-divider"></div>

          <Tooltip
            zIndex={1}
            color="transparent"
            placement="right"
            title={
              <div style={{ padding: '24px' }} className="tooltip-container">
                <div className="tooltip-title">My Account</div>
                <div className="tooltip-description">Manage your profile</div>
              </div>
            }
          >
            <Link to={ACCOUNT} target={linkTarget}>
              <div className="circle-icon">
                <img style={{ width: '16px' }} src={MyAccountIcon} />
              </div>
            </Link>
          </Tooltip>

          <Tooltip
            zIndex={1}
            color="transparent"
            placement="right"
            title={
              <div style={{ padding: '24px' }} className="tooltip-container">
                <div className="tooltip-title">Logout</div>
                <div className="tooltip-description">
                  Logout securely from your account
                </div>
              </div>
            }
          >
            <div onClick={logout} className="circle-icon">
              <img style={{ width: '16px' }} src={LogoutIcon} />
            </div>
          </Tooltip>
        </div>

        <div className="burger-right-container">
          <div
            className={`burger-menu ${isMenuOpen ? 'open' : ''}`}
            onClick={handleMenuClick}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        {isImpersonating() && (
          <Alert
            message="You are currently impersonating another user's account. Click to stop."
            type="info"
            onClick={stopImpersonating}
            showIcon
            style={{ cursor: 'pointer', textAlign: 'center' }}
          />
        )}
        <div
          style={{
            backgroundColor: '#121212',
            height: '100vh',
            paddingLeft: '48px',
            paddingTop: '72px',
          }}
          className={mergeClassNames(
            'fixed inset-0 z-9999 hidden laptop:hidden',
            { 'flex flex-col': isMenuOpen }
          )}
        >
          {/*HEADER MOBILE LINKS HERE*/}
          {isMentor && (
            <Link
              onClick={closeMenuHandler}
              className="mobile-link-item"
              to={MY_STUDENTS}
              target={linkTarget}
            >
              My Students
            </Link>
          )}

          <Link
            onClick={closeMenuHandler}
            className="mobile-link-item"
            to={COURSES}
            target={linkTarget}
          >
            Courses
          </Link>

          <Link
            onClick={closeMenuHandler}
            to={{
              pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
            }}
            target="_blank"
            className="mobile-link-item"
          >
            My Files
          </Link>

          {isMentor && (
            <Link
              onClick={closeMenuHandler}
              className="mobile-link-item"
              to={SESSION_HISTORY}
              target={linkTarget}
            >
              Session History
            </Link>
          )}

          {isMentor && (
            <Link
              onClick={closeMenuHandler}
              className="mobile-link-item"
              to={KNOWLEDGE_BASE_ASSISTANT}
              target={linkTarget}
            >
              Knowledge Base Assistant
            </Link>
          )}

          <div className="icon-divider"></div>

          <Link
            onClick={closeMenuHandler}
            to={ACCOUNT}
            className="mobile-link-item"
            target={linkTarget}
          >
            My Account
          </Link>

          <Link
            to=""
            onClick={() => {
              logout();
              closeMenuHandler();
            }}
            className="mobile-link-item"
          >
            Logout
          </Link>

          {isMentor && (
            <>
              <div className="icon-divider"></div>
              <div className="mentor-drive-balance-container">
                <a
                  href={`https://drive.google.com/drive/folders/${folderId}`}
                  target="_blank"
                  style={{
                    marginRight: '8px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={closeMenuHandler}
                  rel="noreferrer"
                >
                  <div
                    style={{ width: '40px', height: '40px' }}
                    className={`${pageStyle.driveIcon} ${pageStyle.driveIconButton}`}
                  />
                </a>
                <BalanceInfo onClick={closeMenuHandler} />
              </div>
            </>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default HeaderV3;
