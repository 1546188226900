import { useAuth0 } from '@auth0/auth0-react';
import { MobileProvider } from '@components/Navigation/MobileContext';
import Auth from '@modules/Auth';
import LoginErrorView from '@modules/Auth/LoginErrorScreen';
import ParentView from '@modules/Auth/ParentView';
import ReferralThankYouView from '@modules/Auth/ReferralThankYouView/index';
import ReferralView from '@modules/Auth/ReferralView/index';
import TermsAndConditionsView from '@modules/Auth/TermsAndConditionsView';
import Licenses from '@modules/Licenses';
import PaymentSuccess from '@modules/PaymentSuccess';
import useMixpanelPageView from '@services/useMixpanelPageView';
import { USE_AUTH0 } from '@shared/frontendEnv';
import { useAuthState } from '@shared/react';
import { storage } from '@shared/react/UniversalStorage';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import useErrLogs from './services/useErrLog';
import { AdminView, UserView } from './views';

const Router: React.FC = () => {
  useErrLogs();
  useMixpanelPageView();

  const history = useHistory();

  const { isLoggedIn, isAdmin, isLoading } = useAuthState();

  const { error, isAuthenticated, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();
  const [authFlag] = useState<boolean>(USE_AUTH0 === '1');

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [loading, setLoading] = useState(true);
  const [hasAdminRoles, setHasAdminRoles] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchToken = async () => {
      try {
        let token = storage.getItem('auth0token');
        if (!token) {
          token = await getAccessTokenSilently();
          storage.setItem('auth0token', token);
        }
      } catch (err: any) {
        /* empty */
      }
    };

    fetchToken();
  }, [isAuthenticated, isLoggedIn]);

  useEffect(() => {
    setHasAdminRoles(isAdmin);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [isAdmin, isAuthenticated]);

  useEffect(() => {
    if (!isLoggedIn && !isLoading && localStorage.getItem('auth0token')) {
      loginWithRedirect();
    }
  }, [isLoggedIn, isLoading, localStorage.getItem('auth0token')]);

  if (loading) {
    return <></>;
  } else {
    if (
      (isAuthenticated || isLoggedIn) &&
      window.location.pathname.startsWith('/referral')
    ) {
      return (
        <Switch>
          <Route path="/referral/:id" component={ReferralView} />
          <Route path="/referral" component={ReferralView} />
        </Switch>
      );
    }

    if (
      ((isAuthenticated || isLoggedIn) &&
        window.location.pathname === '/thank-you') ||
      window.location.pathname === '/thank-you/'
    ) {
      return (
        <Switch>
          <Route path="/thank-you" component={ReferralThankYouView} />
        </Switch>
      );
    }

    if (isLoggedIn && window.location.pathname === '/terms-and-conditions') {
      return (
        <Switch>
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditionsView}
          />
        </Switch>
      );
    }

    if (isLoggedIn && window.location.pathname.includes('/parent-view')) {
      return (
        <Switch>
          <Route path="/parent-view/:studentId" component={ParentView} />
        </Switch>
      );
    }

    if (!(isAuthenticated || isLoggedIn) || error) {
      if (
        (window.location.pathname === '/' ||
          window.location.pathname === '/auth' ||
          window.location.pathname === '/auth/') &&
        authFlag
      ) {
        if (error?.toString().includes('block')) {
          history.push('/login-error');
        } else {
          loginWithRedirect();
        }
      }

      return (
        <Switch>
          <Route path="/parent-view/:studentId" component={ParentView} />
          <Route path="/auth" component={Auth} />
          <Route path="/licenses" component={Licenses} />
          <Route
            path="/payment/success/:invoiceId"
            component={PaymentSuccess}
          />
          <Route path="/referral/:id" component={ReferralView} />
          <Route path="/referral" component={ReferralView} />
          <Route path="/thank-you" component={ReferralThankYouView} />
          <Route path="/login-error" component={LoginErrorView} />
          <Route
            path="/terms-and-conditions"
            component={TermsAndConditionsView}
          />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <MobileProvider>
        <ConfigProvider locale={enGB}>
          <div className="main_wrapper">
            {!isLoading && hasAdminRoles && !impersonateId && isLoggedIn && (
              <AdminView />
            )}
            {!isLoading && (!hasAdminRoles || impersonateId) && isLoggedIn && (
              <UserView />
            )}
          </div>
        </ConfigProvider>
      </MobileProvider>
    );
  }
};

export default Router;
