import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import SessionsEditor from '@modules/Admin/Users/EnrollmentDetails/UpdateEnrollmentModal/SessionsEditor';
import { useAddSession, useDeleteSession } from '@shared/react';
import { Enrollment } from '@shared/types';
import styles from '@styles/components/sessions-menu.module.scss';
import { Button, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';

type Props = {
  enrollment: Enrollment;
  sessionId?: string;
};

const SessionsViewer: React.FC<Props> = ({ enrollment, sessionId }) => {
  const [sessionIndex, setSessionIndex] = useState<number | undefined>(
    enrollment?.sessions?.findIndex(
      (session) => sessionId === session?.meetings[0]
    ) !== -1
      ? enrollment?.sessions?.findIndex(
          (session) => sessionId === session?.meetings[0]
        )
      : undefined
  );

  // Auto select first session
  useEffect(() => {
    if (sessionIndex !== undefined || !enrollment) {
      return;
    }

    if (enrollment.sessions.length) {
      setSessionIndex(0);
    }
  }, [enrollment]);

  const { mutate: addSession } = useAddSession({
    onSuccess() {
      message.success('Session added successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  const { mutate: deleteSession } = useDeleteSession({
    onSuccess() {
      message.success('Session deleted successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  if (!enrollment) {
    return null;
  }

  const { sessions } = enrollment;

  return (
    <div>
      <Popconfirm
        title="Are you sure you want to add a session?"
        onConfirm={() => {
          addSession({ enrollmentId: enrollment._id });
        }}
      >
        <Button style={{ marginBottom: '16px' }}>
          <PlusOutlined /> Add new meeting slot
        </Button>
      </Popconfirm>
      <Popconfirm
        title="Are you sure you want to delete a session?"
        onConfirm={() => {
          deleteSession({
            enrollmentId: enrollment._id,
            sessionIndex: sessionIndex ?? -1,
          });
        }}
      >
        <Button style={{ marginBottom: '16px', marginLeft: '10px' }}>
          <MinusOutlined />
          Delete selected meeting slot
        </Button>
      </Popconfirm>
      <div className={styles.sessionsNavigator}>
        <div className={styles.sessionsNavigatorItems}>
          {sessions.map((session, index) => {
            const activeClass =
              sessionIndex === index ? styles.sessionsNavigatorItemActive : '';
            return (
              <div
                key={index}
                className={`${styles.sessionsNavigatorItem} ${activeClass}`}
                onClick={() => setSessionIndex(index)}
              >
                <span>{index + 1}</span>
              </div>
            );
          })}
        </div>
      </div>

      <div style={{ marginTop: '15px' }}>
        {sessionIndex === undefined && <span>Select a session</span>}
        {sessionIndex !== undefined && (
          <SessionsEditor
            session={sessions[sessionIndex]}
            sessionIndex={sessionIndex}
            enrollment={enrollment}
          />
        )}
      </div>
    </div>
  );
};

export default SessionsViewer;
