import { ExclamationCircleFilled } from '@ant-design/icons';
import { MeetingModal } from '@components';
import Index from '@components/V3/EnrollmentIcon';
import { useModal } from '@hooks';
import { Enrollment } from '@shared/common';
import { useEndEnrollment } from '@shared/react';
import { capitalizeFirstLetter } from '@shared/utils/string';
import styles from '@styles/components/table.module.scss';
import { Modal, message, Button } from 'antd';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../v3/global';
const { confirm } = Modal;

interface Props {
  studentEnrollment: Enrollment;
  showDetailsModal: any;
  completedNumber: number;
  isEven: boolean;
  refetch?: () => any;
}

const StudentRow: React.FC<Props> = ({
  studentEnrollment: enrollment,
  showDetailsModal,
  completedNumber,
  isEven,
  refetch,
}) => {
  const [Modal, show] = useModal(MeetingModal);
  const { course } = enrollment;

  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  const { mutate: endEnrollment } = useEndEnrollment({
    onSuccess() {
      message.success('Enrollment ended successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  const showConfirm = () => {
    confirm({
      title: 'Are you sure that you want to end this course?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p>
            Student: <b>{enrollment.user?.fullName}</b>
          </p>
          <p>
            Curs: <b>{course.title}</b>
          </p>
        </div>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: 'End course',
      onOk() {
        endEnrollment({
          enrollmentId: enrollment._id,
        });
        if (refetch) {
          refetch().then(() => refetch());
        }
      },
    });
  };

  let status = '';
  if (enrollment.ended) {
    status = 'alumni';
  } else if (enrollment.user?.isDezactivated) {
    status = 'deactivated';
  } else if (enrollment.sessions.find((session) => session?.meetings.length)) {
    status = 'active';
  } else {
    status = 'upcoming';
  }

  const availableToEnd =
    // allow all enrollments to be completed, for the moment
    // completedNumber === enrollment.sessions.length &&
    status !== 'upcoming' && !enrollment.ended;
  // && !enrollment.user.isDezactivated;

  const defaultEnrollment: Enrollment = enrollment;
  const defaultStudent: {
    fullName?: string;
    id: string;
  } = {
    fullName: enrollment.user?.fullName,
    id: enrollment.user?._id,
  };

  return (
    <>
      {Modal}
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {}
        }
        className={`${classes} ${styles.gridTableRowFirst}`}
      >
        <span style={{ color: COLORS.TEXT_PRIMARY }}>
          {enrollment.user?.fullName}
        </span>
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {}
        }
        className={classes}
      >
        <Index
          enrollmentTitle={course.title || ''}
          enrollmentColor={course.color || ''}
          courseId={course._id}
          abbreviation={course.abbreviation || ''}
        />
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {}
        }
        className={classes}
      >
        <span style={{ color: COLORS.TEXT_PRIMARY }}>
          {moment(enrollment.startDate).format('DD MMM YYYY')}
        </span>
      </div>
      <div
        className={classes}
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {
                color: availableToEnd ? 'green' : undefined,
              }
        }
      >
        <span style={{ color: COLORS.TEXT_PRIMARY }}>
          {completedNumber ?? 0} / {enrollment.sessions.length}
        </span>
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {}
        }
        className={classes}
      >
        <span style={{ color: COLORS.TEXT_PRIMARY }}>
          {enrollment.user?.graduationYear || 'N/A'}
        </span>
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {}
        }
        className={classes}
      >
        <div
          className={styles.tableStatus}
          style={{
            backgroundColor:
              status === 'active'
                ? '#33a9ab'
                : status === 'upcoming'
                ? '#D53030'
                : status === 'alumni'
                ? '#FEA746'
                : status === 'deactivated'
                ? 'red'
                : 'black',
          }}
        >
          <span style={{ color: COLORS.TEXT_PRIMARY }}>
            {capitalizeFirstLetter(status || '')}
          </span>
        </div>
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
              }
            : {}
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {status === 'upcoming' && (
          <Button
            style={{ backgroundColor: COLORS.BLACK, marginRight: '8px' }}
            className={styles.tableButton}
            onClick={() =>
              show({
                isOnHomePage: true,
                defaultEnrollment: defaultEnrollment,
                defaultStudent: defaultStudent,
              })
            }
          >
            <span style={{ color: COLORS.TEXT_PRIMARY }}>Schedule Meeting</span>
          </Button>
        )}
        <Button
          style={{ backgroundColor: COLORS.BLACK }}
          className={styles.tableButton}
          onClick={() =>
            showDetailsModal({
              studentId: enrollment.user?._id,
              courseId: enrollment.course._id,
              studentName: enrollment.user?.fullName,
            })
          }
        >
          <span style={{ color: COLORS.TEXT_PRIMARY }}>View Details</span>
        </Button>
        {availableToEnd && (
          <div
            className={styles.tableButton}
            style={{ marginLeft: 5, backgroundColor: 'orange', color: 'white' }}
            onClick={showConfirm}
          >
            End
          </div>
        )}
      </div>
    </>
  );
};

export default StudentRow;
