import { StopOutlined } from '@ant-design/icons';
import { Meeting } from '@shared/common';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { COLORS } from '../../../v3/global';
import GiveFeedbackButtonMentor from '../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';
import GiveFeedbackButtonStudent from '../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonStudent';
import EnrollmentIcon from '../EnrollmentIcon';
import './next-meeting.styles.scss';
import '../../../views/UserView/Home/Common/Buttons/meeting-card-buttons.styles.scss';
import { Link } from 'react-router-dom';

interface Props {
  meeting: Meeting;
  otherName: string;
  meetingNeedingFeedback?: boolean;
  meetingNeedingFeedbackStudent?: boolean;
  isMentor?: boolean;
  isPreview?: boolean;
  abbreviationPreview?: string;
  hideJoinAndRescheduleBtn?: boolean;
  showDetailsModal?: any;
}

const NextMeeting: React.FC<Props> = ({
  meeting,
  otherName,
  children,
  meetingNeedingFeedback,
  meetingNeedingFeedbackStudent,
  isMentor,
  isPreview,
  abbreviationPreview,
  hideJoinAndRescheduleBtn,
  showDetailsModal,
}) => {
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const divParentRef = useRef<HTMLDivElement | null>(null);
  const [divParentWidth, setDivParentWidth] = useState(0);

  const { startDate, name } = meeting;
  const [isClicked, setIsClicked] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);

  const handleArrowClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (divParentRef.current) {
        const width = divParentRef.current.offsetWidth;
        setDivParentWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;
      const tooltipNode = ReactDOM.findDOMNode(tooltipRef.current);
      const isClickedOutside = tooltipNode && !tooltipNode.contains(target);
      const isClickedInsideContent = (target as Element)?.closest(
        '.ant-tooltip-inner'
      );

      if (!isClickedInsideContent && isClickedOutside) {
        setIsClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isClicked]);

  const content = () => {
    if (isMentor && meeting?.enrollment?.user?.isDezactivated) {
      return (
        <div
          style={{
            marginTop:
              isMentor && meeting?.enrollment?.user?.isDezactivated ? -20 : '',
          }}
          className="next-meeting-content-container"
        >
          <div>
            {isMentor && meeting?.enrollment?.user?.isDezactivated && (
              <StopOutlined
                style={{
                  color: COLORS.ERROR,
                }}
                className="next-meeting-stop-icon"
              />
            )}
            <EnrollmentIcon
              abbreviation={
                abbreviationPreview ?? meeting?.enrollment?.abbreviation
              }
              enrollmentColor={
                meeting?.enrollment?.color
                  ? meeting?.enrollment?.color
                  : meeting?.enrollment?.user?.isDezactivated
                  ? COLORS.DARK_GREY3
                  : name.toLowerCase().includes('coach') ||
                    name.toLowerCase().includes('training')
                  ? COLORS.PRIMARY
                  : name.toLowerCase().includes('academic')
                  ? COLORS.PURPLE
                  : COLORS.GOLD
              }
              enrollmentTitle={
                abbreviationPreview
                  ? abbreviationPreview
                  : meeting?.enrollment?.abbreviation
                  ? meeting?.enrollment?.abbreviation
                  : meeting?.enrollment?.course?.title
              }
              size={48}
              fontSize="16px"
              courseId={meeting?.enrollment?.course?._id}
              margin={'16px'}
            />
          </div>
          <div
            style={{
              marginTop:
                isMentor && meeting?.enrollment?.user?.isDezactivated ? 20 : '',
            }}
            className="next-meeting-title-container"
          >
            <div
              style={{
                width:
                  meetingNeedingFeedback || meetingNeedingFeedbackStudent
                    ? 'calc(100% - 16px)'
                    : '100%',
              }}
              className="next-meeting-title"
            >
              <span
                onClick={() => {
                  if (showDetailsModal) {
                    showDetailsModal({
                      studentId: meeting.enrollment.user._id,
                      courseId: meeting.enrollment.course._id,
                      studentName: otherName,
                    });
                  }
                }}
                style={{
                  cursor: isMentor ? 'pointer' : 'default',
                  fontSize: '13px',
                  textDecoration:
                    isMentor && meeting?.enrollment?.user?.isDezactivated
                      ? 'line-through'
                      : 'none',
                  color:
                    isMentor && isHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                }}
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => setIsHovered(false)}
              >
                {otherName}
              </span>
              <span style={{ fontSize: '13px', color: COLORS.TEXT_PRIMARY }}>
                {' '}
                - {meeting?.currentSession ?? '-'} of{' '}
                {meeting?.totalSessions ?? '-'}
              </span>
            </div>

            <div style={{ width: divParentWidth }}>
              <div
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width:
                    meetingNeedingFeedback || meetingNeedingFeedbackStudent
                      ? 'calc(100% - 104px - 94px - 16px)'
                      : 'calc(100% - 104px - 94px)',
                }}
                className="next-meeting-date"
              >
                <span
                  style={{
                    fontSize: '13px',
                    textDecoration:
                      isMentor && meeting?.enrollment?.user?.isDezactivated
                        ? 'line-through'
                        : 'none',
                  }}
                >
                  {moment(startDate).format('DD MMM HH:mm')},{' '}
                  {meeting?.enrollment?.course?.title}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            color: 'inherit',
            display: 'flex',
            maxWidth: 'calc(100% - 104px)',
            marginTop:
              isMentor && meeting?.enrollment?.user?.isDezactivated ? -20 : '',
          }}
        >
          <div>
            {isMentor && meeting?.enrollment?.user?.isDezactivated && (
              <StopOutlined
                style={{
                  color: COLORS.ERROR,
                }}
                className="next-meeting-stop-icon"
              />
            )}
            <EnrollmentIcon
              abbreviation={
                abbreviationPreview ?? meeting?.enrollment?.abbreviation
              }
              enrollmentColor={
                meeting?.enrollment?.color
                  ? meeting?.enrollment?.color
                  : meeting?.enrollment?.user?.isDezactivated
                  ? COLORS.DARK_GREY3
                  : name.toLowerCase().includes('coach') ||
                    name.toLowerCase().includes('training')
                  ? COLORS.PRIMARY
                  : name.toLowerCase().includes('academic')
                  ? COLORS.PURPLE
                  : COLORS.GOLD
              }
              enrollmentTitle={
                abbreviationPreview
                  ? abbreviationPreview
                  : meeting?.enrollment?.abbreviation
                  ? meeting?.enrollment?.abbreviation
                  : meeting?.enrollment?.course?.title
              }
              size={48}
              fontSize="16px"
              courseId={meeting?.enrollment?.course?._id}
              margin={'16px'}
            />
          </div>

          <div
            style={{
              marginTop:
                isMentor && meeting?.enrollment?.user?.isDezactivated ? 20 : '',
            }}
            className="next-meeting-title-container"
          >
            <div
              style={{
                width:
                  meetingNeedingFeedback || meetingNeedingFeedbackStudent
                    ? 'calc(100% - 16px)'
                    : '100%',
              }}
              className="next-meeting-title"
            >
              <span
                onClick={() => {
                  if (showDetailsModal) {
                    showDetailsModal({
                      studentId: meeting.enrollment.user._id,
                      courseId: meeting.enrollment.course._id,
                      studentName: otherName,
                    });
                  }
                }}
                style={{
                  cursor: isMentor ? 'pointer' : 'default',
                  textDecoration:
                    isMentor && meeting?.enrollment?.user?.isDezactivated
                      ? 'line-through'
                      : isMentor && isHovered
                      ? 'underline'
                      : 'none',
                  fontSize: '13px',
                  color:
                    isMentor && isHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                }}
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => setIsHovered(false)}
              >
                {otherName}
              </span>
              <span style={{ fontSize: '13px', color: COLORS.TEXT_PRIMARY }}>
                {' '}
                - {meeting?.currentSession ?? '-'} of{' '}
                {meeting?.totalSessions ?? '-'}
              </span>
            </div>

            <div style={{ width: divParentWidth }}>
              <div
                style={{
                  color: COLORS.TEXT_SECONDARY,
                  width:
                    meetingNeedingFeedback || meetingNeedingFeedbackStudent
                      ? 'calc(100% - 104px - 94px - 16px)'
                      : 'calc(100% - 104px - 94px)',
                }}
                className="next-meeting-date"
              >
                <span
                  style={{
                    fontSize: '13px',
                    textDecoration:
                      isMentor && meeting?.enrollment?.user?.isDezactivated
                        ? 'line-through'
                        : 'none',
                  }}
                >
                  {moment(startDate).format('DD MMM HH:mm')},{' '}
                  {meeting?.enrollment?.course?.title}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <Tooltip
      zIndex={1}
      color="transparent"
      placement="topLeft"
      trigger="hover"
      title={
        isMentor && meeting?.enrollment?.user?.isDezactivated ? (
          <div
            style={{
              padding: '12px 16px',
              color: COLORS.TEXT_PRIMARY,
            }}
            className="tooltip-container"
          >
            User has been deactivated. For any questions please contact our
            support team.
          </div>
        ) : (
          <div style={{ padding: '0px' }}></div>
        )
      }
    >
      <div
        ref={divParentRef}
        className="next-meeting-card-container bg-surfaceHover"
      >
        {content()}

        <div className="next-meeting-card-content-container">
          {/*JOIN MEETING ICON BUTTON*/}
          {!(isMentor && meeting?.enrollment?.user?.isDezactivated) && (
            <div className="next-meeting-button-tooltip-container">
              <Tooltip
                zIndex={1}
                color="transparent"
                placement="topRight"
                trigger="hover"
                title={
                  <div className="feedback-button-tooltip">
                    <span
                      style={{
                        color: COLORS.TEXT_SECONDARY,
                      }}
                    >
                      Join Meeting
                    </span>
                  </div>
                }
              >
                <Link
                  rel="noopener noreferrer"
                  to={`/video-meeting/${meeting._id}`}
                >
                  <span className="button-icon-container">
                    <div className="join-meeting-button-icon" />
                  </span>
                </Link>
              </Tooltip>
            </div>
          )}

          {meetingNeedingFeedback && (
            <div className="next-meeting-button-tooltip-container">
              <GiveFeedbackButtonMentor meeting={meeting} />
            </div>
          )}

          {meetingNeedingFeedbackStudent && (
            <div className="next-meeting-button-tooltip-container">
              <Tooltip
                zIndex={1}
                color="transparent"
                placement="bottom"
                trigger="hover"
                title={
                  <div
                    style={{
                      padding: '24px',
                      color: COLORS.TEXT_PRIMARY,
                    }}
                    className="tooltip-container"
                  />
                }
              >
                <GiveFeedbackButtonStudent meeting={meeting} />
              </Tooltip>
            </div>
          )}

          <Tooltip
            ref={tooltipRef}
            zIndex={9999}
            color="transparent"
            placement="bottom"
            trigger="click"
            visible={isPreview ? false : isClicked}
            title={
              <div
                style={{
                  color: COLORS.TEXT_PRIMARY,
                  backgroundColor: COLORS.COMPONENT_BACKGROUND,
                  minWidth: '208px',
                }}
                className="tooltip-container next-meeting-card-tooltip-menu-container"
              >
                {children}
              </div>
            }
          >
            <div
              onClick={handleArrowClick}
              className="next-meeting-card-more-button-container"
            >
              {isClicked ? (
                <span className="button-icon-container">
                  <div className="meeting-card-more-icon-clicked" />
                </span>
              ) : (
                <span className="button-icon-container">
                  <div className="meeting-card-more-icon" />
                </span>
              )}
            </div>
          </Tooltip>
        </div>
      </div>
    </Tooltip>
  );
};

export default NextMeeting;
