import MentorFeedbackModalSession from '@modules/Base/Home/PendingFeedback/MentorFeedbackModalSession';
import { NO_OF_HOURS_FOR_FEEDBACK, Meeting } from '@shared/common';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import './meeting-card-buttons.styles.scss';

interface JoinButtonProps {
  meeting: Meeting;
  afterSave?: () => void;
}

const hoursDiff = (dt1: any, dt2: any) => {
  const now = dt2;
  const then = dt1;

  const ms = moment(now, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(then, 'DD/MM/YYYY HH:mm:ss')
  );
  const d = moment.duration(ms);
  // const s = Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss');
  const s = Math.floor(d.asHours());

  return s;
};

const date = new Date();

const GiveFeedbackButtonMentor: React.FC<JoinButtonProps> = ({
  meeting,
  afterSave,
}) => {
  const [showModal, setShowModal] = useState(false);

  const feedbackTooltip = (
    <div>
      {hoursDiff(
        date.toLocaleString(),
        moment(meeting.startDate)
          .add(NO_OF_HOURS_FOR_FEEDBACK, 'hours')
          .format('DD/MM/YYYY, HH:mm:ss')
      ) >= 0 ? (
        <div>
          <div>
            The session is marked as complete only if the feedback was sent on
            time.
          </div>
          <br />
          <div>
            You still have{' '}
            <span style={{ color: COLORS.PRIMARY }}>
              {hoursDiff(
                date.toLocaleString(),
                moment(meeting.startDate)
                  .add(NO_OF_HOURS_FOR_FEEDBACK, 'hours')
                  .format('DD/MM/YYYY, HH:mm:ss')
              ) >= 48
                ? Math.floor(
                    hoursDiff(
                      date.toLocaleString(),
                      moment(meeting.startDate)
                        .add(NO_OF_HOURS_FOR_FEEDBACK, 'hours')
                        .format('DD/MM/YYYY, HH:mm:ss')
                    ) / 24
                  ) + ' days left'
                : hoursDiff(
                    date.toLocaleString(),
                    moment(meeting.startDate)
                      .add(NO_OF_HOURS_FOR_FEEDBACK, 'hours')
                      .format('DD/MM/YYYY, HH:mm:ss')
                  ) + ' hrs left'}{' '}
            </span>{' '}
            to send your feedback and become eligible for payment for this
            session.
          </div>
        </div>
      ) : (
        <div>
          <div>
            We can’t verify that you completed this session because you didn’t
            provide feedback in time ({NO_OF_HOURS_FOR_FEEDBACK}h after the
            session ends).
          </div>
          <br />
          <div>
            Payment for this session is not confirmed, please give feedback
            ASAP!
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div>
      {showModal ? (
        <div style={{ maxHeight: '300px' }}>
          <MentorFeedbackModalSession meeting={meeting} />
        </div>
      ) : null}

      <Tooltip
        zIndex={1}
        color="transparent"
        placement="topRight"
        trigger="hover"
        title={
          <div className="feedback-button-tooltip">
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              Give Feedback
            </span>
          </div>
        }
      >
        <span
          onClick={() => setShowModal(!showModal)}
          className="button-icon-container"
        >
          <div className="feedback-button-icon" />
        </span>
      </Tooltip>
    </div>
  );
};

export default GiveFeedbackButtonMentor;
