import { ReactComponent as ChatIcon } from '@assets/icons/video-icons/chat-icon.svg';
import { ReactComponent as MeetingNotesIcon } from '@assets/icons/video-icons/meeting-notes-icon.svg';
import { ReactComponent as ParticipantsIcon } from '@assets/icons/video-icons/participants-icon.svg';
import { ReactComponent as TranscriptIcon } from '@assets/icons/video-icons/transcript-icon.svg';
import IconButton from '@components/V4/IconButton';
import { PageNameType } from '@modules/MeetingVideo/types/PageNameType';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';

const videoActionButtons = [
  { icon: <ChatIcon className="w-full h-full" />, name: 'chat' },
  { icon: <MeetingNotesIcon className="w-full h-full" />, name: 'notes' },
  {
    icon: <ParticipantsIcon className="w-full h-full" />,
    name: 'participants',
  },
  { icon: <TranscriptIcon className="w-full h-full" />, name: 'transcript' },
];

type Props = {
  className?: string;
  activeButton?: PageNameType;
  handleShowSidebar: (pageName: PageNameType) => void;
  setActiveButton: (pageName: PageNameType) => void;
  handleCloseSidebar: () => void;
};

const MeetingActionButtons = (props: Props) => {
  const {
    className,
    activeButton,
    handleShowSidebar,
    setActiveButton,
    handleCloseSidebar,
  } = props;

  const handleClick = (buttonName: PageNameType) => {
    if (activeButton === buttonName) {
      handleCloseSidebar();
    } else {
      setActiveButton(buttonName);
      handleShowSidebar(buttonName);
    }
  };

  return (
    <div className={mergeClassNames('gap-6 flex laptop:block', className)}>
      {videoActionButtons.map((button, index) => (
        <IconButton
          key={index}
          className="w-8 h-8 tablet:w-10 tablet:h-10"
          icon={button.icon}
          isActive={button.name === activeButton}
          variant="secondary"
          size="large"
          onClick={() => handleClick(button.name as PageNameType)}
        />
      ))}
    </div>
  );
};

export default MeetingActionButtons;
