import { Participant } from '@zoom/videosdk';

export const findParticipantsDifference = (
  pastParticipants: Participant[],
  newParticipants: Participant[]
) => {
  const pastParticipantsIdSet = new Set(
    pastParticipants.map((item) => item.userId)
  );
  const newParticipantsIdSet = new Set(
    newParticipants.map((item) => item.userId)
  );

  const addedParticipants = newParticipants.filter(
    (item) => !pastParticipantsIdSet.has(item.userId)
  );

  const removedParticipants = pastParticipants.filter(
    (item) => !newParticipantsIdSet.has(item.userId)
  );

  return { addedParticipants, removedParticipants };
};
