import CourseCard from '@components/V3/CourseCard';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import { COURSES } from '@routes';
import { Course } from '@shared/common';
import { useLoginAndRedirectToCourse } from '@shared/react';
import { Empty } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { COLORS } from '../../../v3/global';
import './your-courses.styles.scss';

type Props = {
  courses?: {
    teachingCourses: Partial<Course>[];
    enrolledInCourses: Partial<Course>[];
  };
};

const EnrolledInCourses = ({ courses }: Props) => {
  const history = useHistory();

  const { mutate: redirectToLMS, isLoading } = useLoginAndRedirectToCourse({
    onSuccess: (data) => {
      if (data?.url) {
        setTimeout(() => {
          window.open(data.url, '_blank');
        });
      }
    },
  });

  const divParentRef = useRef<HTMLDivElement | null>(null);
  const [divParentWidth, setDivParentWidth] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [showAll, setShowAll] = useState(false);
  const itemsPerPage = 6;
  const slicedCourses = showAll
    ? courses?.enrolledInCourses
    : courses?.enrolledInCourses?.slice(0, itemsPerPage);

  const [externalLinkHovered, setExternalLinkHovered] = useState(false);
  const [showAllHovered, setShowAllHovered] = useState(false);

  const handleMouseEnterLink = () => {
    setExternalLinkHovered(true);
  };

  const handleMouseLeaveLink = () => {
    setExternalLinkHovered(false);
  };

  const handleMouseEnterShowAll = () => {
    setShowAllHovered(true);
  };

  const handleMouseLeaveLinkShowAll = () => {
    setShowAllHovered(false);
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  const sectionTitle = 'Enrolled In';

  useEffect(() => {
    const handleResize = () => {
      if (divParentRef.current) {
        const width = divParentRef.current.offsetWidth;
        setDivParentWidth(width);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="your-courses-header-container">
        <div className="your-courses-section-title">
          <SectionTitle title={sectionTitle} />
        </div>

        {courses?.enrolledInCourses &&
          courses?.enrolledInCourses?.length > itemsPerPage &&
          windowWidth > 1017 && (
            <div
              style={{
                cursor: 'pointer',
                fontSize: '13px',
                fontWeight: 'bold',
              }}
              onClick={() => setShowAll(!showAll)}
              onMouseEnter={handleMouseEnterShowAll}
              onMouseLeave={handleMouseLeaveLinkShowAll}
            >
              {showAll ? (
                <span
                  style={{
                    textDecoration: showAllHovered ? 'underline' : 'none',
                    whiteSpace: 'nowrap',
                    color: showAllHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                  }}
                >
                  See Less
                </span>
              ) : (
                <span
                  style={{
                    textDecoration: showAllHovered ? 'underline' : 'none',
                    whiteSpace: 'nowrap',
                    color: showAllHovered
                      ? COLORS.PRIMARY
                      : COLORS.TEXT_PRIMARY,
                  }}
                >
                  See All
                </span>
              )}
            </div>
          )}
        {/*<div*/}
        {/*  onMouseEnter={handleMouseEnterLink}*/}
        {/*  onMouseLeave={handleMouseLeaveLink}*/}
        {/*  style={{ marginLeft: '16px', cursor: 'pointer' }}*/}
        {/*  onClick={() =>*/}
        {/*    window.open(*/}
        {/*      'https://lms.upgrade.education/author/welcome',*/}
        {/*      '_blank'*/}
        {/*    )*/}
        {/*  }*/}
        {/*>*/}
        {/*  <img*/}
        {/*    src={externalLinkHovered ? ExternalLinkPrimary : ExternalLinkWhite}*/}
        {/*    alt="external-link"*/}
        {/*    style={{ minWidth: '16px', maxWidth: '16px' }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>

      <div
        ref={divParentRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
        className="your-courses-content"
      >
        {isLoading ? (
          <div className="loading-spinner-container">
            <AgoraSpinner fontSize={40} />
          </div>
        ) : courses?.enrolledInCourses?.length &&
          !isLoading &&
          windowWidth > 1017 ? (
          slicedCourses?.map((course: any, index: number) => (
            <div
              key={index}
              onClick={() => {
                if (course?.course?.lmsCourseId) {
                  redirectToLMS({ courseId: course?.course?.lmsCourseId });
                } else {
                  history.push(`${COURSES}/${course?.course?._id}`);
                }
              }}
              style={{
                cursor: 'pointer',
                marginBottom: '16px',
                width:
                  windowWidth > 1340
                    ? `calc(${divParentWidth / 2}px - 16px)`
                    : '100%',
              }}
            >
              <CourseCard
                image={
                  course?.course?.headerImage?.['160x160']
                    ? course.course.headerImage['160x160']
                    : null
                }
                title={course?.course?.title}
                subtitle={course.course?.subtitle}
                color={course.course?.color}
              />
            </div>
          ))
        ) : courses?.enrolledInCourses?.length &&
          !isLoading &&
          windowWidth <= 1017 ? (
          courses?.enrolledInCourses?.map((course: any, index: number) => (
            <div
              key={course?.course?._id}
              onClick={() => {
                if (course?.course?.lmsCourseId) {
                  redirectToLMS({ courseId: course?.course?.lmsCourseId });
                } else {
                  history.push(`${COURSES}/${course?.course?._id}`);
                }
              }}
              style={{
                cursor: 'pointer',
                marginBottom: '16px',
                width: '100%',
              }}
            >
              <CourseCard
                image={
                  course?.course?.headerImage?.['160x160']
                    ? course.course.headerImage['160x160']
                    : null
                }
                title={course?.course?.title}
                subtitle={course?.course?.subtitle}
                color={course?.course?.color}
              />
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {!isLoading && <Empty />}
          </div>
        )}
      </div>
    </div>
  );
};

export default EnrolledInCourses;
