import EditIcon from '@assets/icons/edit-icon.svg';
import EditEnrolmentMeetingsModalRow from '@components/V3/EnrollmentsModalPages/EditEnrolmentMeetingsModalRow';
import EditEnrolmentMentorModalRow from '@components/V3/EnrollmentsModalPages/EditEnrolmentMentorModalRow';
import AgoraSpinner from '@components/V3/Utils/AgoraSpinner';
import LockedFormInputV3 from '@components/V3/Utils/LockedFormInputV3';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { DATE_FORMAT } from '@modules/Admin/Users/EnrollmentDetails/UpdateEnrollmentModal/useDetails';
import { MeetingStatus, ModelFlagType } from '@shared/constants';
import {
  useAddSession,
  useDeleteSession,
  useEnrollment,
  useEnrollmentMentorHistory,
  useMentors,
  useUpdateEnrollment,
} from '@shared/react';
import { message, Popconfirm } from 'antd';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { COLORS } from '../../../v3/global';
import './enrollments-modal-flags.styles.scss';

type Props = {
  flagType: number;
  reference: any;
  handleEditEnrolmentTitle: (course: any, mentor: any, student: any) => any;
};

const EditEnrolmentPageFlags = ({
  flagType,
  reference,
  handleEditEnrolmentTitle,
}: Props) => {
  const isMeetingType = ModelFlagType[flagType] === 'Meeting';
  const { data: currentEnrolmentData, isLoading } = useEnrollment(
    isMeetingType ? reference?.enrollment : reference?._id
  );

  const { data: mentorHistory } = useEnrollmentMentorHistory(
    isMeetingType ? reference?.enrollment : reference?._id
  );

  const { mutate: addSession } = useAddSession({
    onSuccess() {
      message.success('Meeting slot added successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  const { mutate: deleteSession } = useDeleteSession({
    onSuccess() {
      message.success('Meeting slot deleted successfully');
    },
    onError(err: any) {
      message.error(err?.message || 'An error has occurred!');
    },
  });

  const { data: mentorsData, isLoading: mentorsLoading } = useMentors({
    isDezactivated: 'unchecked',
  });

  const { mutate: updateEnrollment } = useUpdateEnrollment({
    onSuccess: () => {
      message.success('Enrollment updated successfully');
    },
  });

  const [startDate, setStartDate] = useState(currentEnrolmentData?.startDate);
  const [estimatedEndDate, setEstimatedEndDate] = useState(
    currentEnrolmentData?.estimatedEndDate
  );

  useEffect(() => {
    if (currentEnrolmentData) {
      setStartDate(currentEnrolmentData.startDate);
      setEstimatedEndDate(currentEnrolmentData.estimatedEndDate);
    }
  }, [currentEnrolmentData]);

  const [selectedTab, setSelectedTab] = useState(
    isMeetingType ? 'Meetings' : 'Mentor'
  );

  useEffect(() => {
    if (!isLoading) {
      handleEditEnrolmentTitle(
        currentEnrolmentData?.course?.title,
        currentEnrolmentData?.mentor?.fullName ?? 'TBD',
        currentEnrolmentData?.user?.fullName
      );
    }
  }, [currentEnrolmentData, isLoading]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        {/*ENROLMENT INFO*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginRight: '32px',
          }}
          className="modal-edit-enrolment-page-status"
        >
          {isMeetingType && <span>Meeting Status:</span>}
          <span>Start Date:</span>
          <span>End Date:</span>
          <span>Total Meetings:</span>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Mentor:
          </span>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
          className="modal-edit-enrolment-page-status"
        >
          {isMeetingType && <span>{MeetingStatus[reference?.status]}</span>}
          <span>
            <LockedFormInputV3
              type="date"
              style={{ fontSize: '14px' }}
              value={startDate ? moment(startDate).format(DATE_FORMAT) : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const date = moment(e.target.value);
                if (date.toString() === 'Invalid date') {
                  return;
                }
                setStartDate(date.format(DATE_FORMAT));
                updateEnrollment({
                  enrollmentId: currentEnrolmentData?._id ?? '',
                  startDate: moment(e.target.value).toISOString(),
                });
              }}
            />
          </span>
          <span>
            <LockedFormInputV3
              type="date"
              style={{ fontSize: '14px' }}
              value={
                estimatedEndDate
                  ? moment(estimatedEndDate).format(DATE_FORMAT)
                  : ''
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const date = moment(e.target.value);
                if (date.toString() === 'Invalid date') {
                  return;
                }
                setEstimatedEndDate(date.format(DATE_FORMAT));
                updateEnrollment({
                  enrollmentId: currentEnrolmentData?._id ?? '',
                  estimatedEndDate: moment(e.target.value).toISOString(),
                });
              }}
            />
          </span>
          <span>
            {currentEnrolmentData?.noOfCompletedSessions ?? 0}/
            {currentEnrolmentData?.noOfSessions ?? 0}
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '8px',
              width: '40%',
            }}
            className="mentors-table-row-text modal-edit-enrolment-page-status"
          >
            {mentorsLoading ? (
              <AgoraSpinner fontSize={13} />
            ) : (
              <select
                value={currentEnrolmentData?.mentor?.fullName ?? 'TBD'}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  updateEnrollment({
                    enrollmentId: currentEnrolmentData?._id ?? '',
                    mentorId: selectedValue,
                  });
                }}
                className="mentors-row-custom-select-component"
                style={{
                  borderColor: COLORS.TEXT_PRIMARY,
                  color: COLORS.TEXT_PRIMARY,
                  backgroundColor: COLORS.COMPONENT_BACKGROUND,
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  appearance: 'none',
                  paddingLeft: '0px',
                  backgroundImage: `url(${EditIcon})`,
                  backgroundSize: '8px 8px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right center',
                  marginRight: '20px',
                  cursor: 'pointer',
                  height: '14px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                  paddingRight: '40px',
                  maxWidth: '132px',
                  fontSize: '12px',
                }}
                placeholder={currentEnrolmentData?.mentor?.fullName}
              >
                <option>{currentEnrolmentData?.mentor?.fullName}</option>
                <option value="TBD">TBD</option>
                {mentorsData?.users?.map((mentor) => (
                  <option value={mentor?._id}>{mentor?.fullName}</option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>

      {/*TABS*/}
      <div style={{ marginBottom: '16px' }}>
        <nav
          style={{ overflowX: 'auto' }}
          className="flex space-x-4"
          aria-label="Tabs"
        >
          <span
            onClick={() => {
              setSelectedTab('Mentor');
            }}
            style={{
              cursor: 'pointer',
              color:
                selectedTab === 'Mentor' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
              background:
                selectedTab === 'Mentor'
                  ? COLORS.PRIMARY
                  : COLORS.DARK_GREY_ALT,
              borderRadius: '32px',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              fontSize: '11px',
              lineHeight: '15px',
            }}
            aria-current={selectedTab === 'Mentor' ? 'page' : undefined}
          >
            Mentor
          </span>

          <span
            onClick={() => {
              setSelectedTab('Meetings');
            }}
            style={{
              cursor: 'pointer',
              color:
                selectedTab === 'Meetings' ? COLORS.BLACK : COLORS.TEXT_PRIMARY,
              background:
                selectedTab === 'Meetings'
                  ? COLORS.PRIMARY
                  : COLORS.DARK_GREY_ALT,
              borderRadius: '32px',
              paddingLeft: '24px',
              paddingRight: '24px',
              paddingTop: '4px',
              paddingBottom: '4px',
              height: '24px',
              fontSize: '11px',
              lineHeight: '15px',
            }}
            aria-current={selectedTab === 'Meetings' ? 'page' : undefined}
          >
            Meetings
          </span>
        </nav>
      </div>

      {/*MENTOR TABLE*/}
      {selectedTab === 'Mentor' && (
        <div>
          {/*COLUMNS*/}
          <div
            style={{
              display: 'flex',
              marginBottom: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '50%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Mentor Name
            </span>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '30%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Date Assigned
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
              className="modal-column-text"
            >
              Status
            </span>
          </div>
          {/*TABLE HEADER DIVIDER*/}
          <div style={{ borderTopWidth: '0.5px' }} className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>

          {/*MENTOR ROWS*/}
          <EditEnrolmentMentorModalRow mentorHistory={mentorHistory ?? []} />
        </div>
      )}

      {/*MEETINGS TABLE*/}
      {selectedTab === 'Meetings' && (
        <div>
          {/*COLUMNS*/}
          <div
            style={{
              display: 'flex',
              marginBottom: '12px',
              width: '100%',
            }}
          >
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '50%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Date
            </span>
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
                width: '30%',
                marginRight: '8px',
              }}
              className="modal-column-text"
            >
              Status
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY, width: '20%' }}
              className="modal-column-text"
            >
              Actions
            </span>
          </div>
          {/*TABLE HEADER DIVIDER*/}
          <div style={{ borderTopWidth: '0.5px' }} className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
          </div>

          {/*MEETINGS ROWS*/}
          {currentEnrolmentData?.sessions?.map(
            (session: any, index: number) => (
              <div>
                <EditEnrolmentMeetingsModalRow
                  session={session}
                  deleteSession={deleteSession}
                  enrolmentId={currentEnrolmentData?._id}
                  meetingIndex={index}
                  openSecondRow={
                    isMeetingType && reference?._id === session?.meetings[0]
                  }
                />
              </div>
            )
          )}

          {/*ADD MEETING BUTTON*/}
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Popconfirm
              icon={null}
              title="Are you sure you want to add a new meeting slot?"
              onConfirm={() => {
                currentEnrolmentData?._id &&
                  addSession({ enrollmentId: currentEnrolmentData._id });
              }}
            >
              <SecondaryButton
                backgroundColor={COLORS.WHITE}
                height="27px"
                padding="4px 10px 4px 10px"
                margin="12px 0 0 0"
              >
                <span className="button-text" style={{ color: COLORS.BLACK }}>
                  Add Meeting Slot
                </span>
              </SecondaryButton>
            </Popconfirm>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditEnrolmentPageFlags;
