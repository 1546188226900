import PrimaryButtonV3 from '@components/V3/PrimaryButtonV3';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import AgoraNewInput from '@components/V3/Utils/InputsV3/AgoraNewInput';
import AgoraTimePicker from '@components/V3/Utils/InputsV3/AgoraTimePicker';
import Modal from '@components/V4/Modal';
import { CreateMeeting } from '@shared/apis/meetings.apis';
import { CloseModalProps, Enrollment, Session } from '@shared/common';
import {
  useAuthState,
  useCreateMeeting,
  useGetActiveStudentsByMentor,
} from '@shared/react';
import { isValidMeetingURL } from '@shared/utils/string';
import AgoraSpinner from '@utils/AgoraSpinner';
import { message } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../v3/global';

type UserArg = { _id: string; fullName: string };

type Props = CloseModalProps & {
  name?: string;
  users?: UserArg[];
  enrollmentId?: string;
  date?: Moment;
  isOnHomePage?: boolean;
  defaultEnrollment?: Enrollment;
  defaultStudent?: {
    fullName?: string;
    id: string;
  };
  defaultSession?: Session;
  postSuccess?: () => void;
};

const MeetingModal = ({
  visible,
  hide,
  date,
  isOnHomePage,
  defaultEnrollment,
  defaultStudent,
  defaultSession,
  postSuccess,
}: Props) => {
  const [isCreating, setIsCreating] = useState(false);

  const { mutate: createMeeting } = useCreateMeeting({
    onSuccess() {
      message.success('Meeting scheduled successfully!');
      setIsCreating(false);
      postSuccess?.();
      hide();
    },
    onError: (error: any) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'Failed to create meeting. Please try again later.';
      message.error(errorMessage);
      setIsCreating(false);
    },
  });
  const { userId: mentorId } = useAuthState();
  const defaultTime = moment().set('minute', 0).add(1, 'h').format('HH:mm');

  const [meetingDetails, setMeetingDetails] = useState<
    Record<keyof CreateMeeting['payload'] | 'time', string>
  >({
    name: 'Agora Meeting',
    enrollmentId: defaultEnrollment ? defaultEnrollment._id : '',
    mentorId: mentorId ?? '',
    students: defaultStudent ? defaultStudent.id : '',
    startDate: '',
    agenda: '',
    time: defaultTime,
  });

  const [formValidity, setFormValidity] = useState({
    name: false,
    enrollmentId: !!defaultEnrollment,
    mentorId: false,
    students: !!defaultStudent,
    startDate: false,
    time: !!defaultTime,
    date: !!(isOnHomePage ? date : moment()),
  });

  const [touched, setTouched] = useState({
    name: false,
    enrollmentId: !!defaultEnrollment,
    mentorId: false,
    students: !!defaultStudent,
    startDate: false,
    time: defaultTime ? true : false,
    date: !!(isOnHomePage ? date : moment()),
  });

  const [selectedDate, setSelectedDate] = useState(
    isOnHomePage ? date : moment()
  );

  const { data: myStudentsData, isLoading: isStudentsLoading } =
    useGetActiveStudentsByMentor();

  const myStudents = myStudentsData?.filter((student, idx, arr) => {
    return idx === arr.findIndex((s) => s?._id === student?._id);
  });

  // Merge prop and hook state and return single variable for clear rendering
  // There should only be one enrollmentId saved
  const selectedEnrollment = useMemo(() => {
    const enrollmentPool =
      myStudentsData
        ?.filter((s) => s._id == meetingDetails.students)
        ?.map((s) => s.enrollment) ?? [];
    if (defaultEnrollment) {
      enrollmentPool.push(defaultEnrollment);
    }

    return enrollmentPool.find((e) => e?._id === meetingDetails.enrollmentId);
  }, [defaultEnrollment, meetingDetails.enrollmentId]);

  // Autofill meeting name
  useEffect(() => {
    if (!selectedEnrollment) {
      return;
    }

    const newName = `${selectedEnrollment?.course.title}`;

    setMeetingDetails({
      ...meetingDetails,
      name: newName,
    });
  }, [selectedEnrollment]);

  useEffect(() => {
    const selectedTime = moment(meetingDetails.time, 'H:mm');
    const startDate = moment(
      selectedDate?.set({
        hour: selectedTime.get('hour'),
        minute: selectedTime.get('minute'),
        second: 0,
        millisecond: 0,
      })
    ).toISOString();

    if (moment().isAfter(startDate)) {
      throw new Error('You cannot schedule a meeting in the past.');
    } else {
      setMeetingDetails({
        ...meetingDetails,
        startDate,
      });
    }
  }, [meetingDetails.time, selectedDate]);

  const submit = () => {
    const isFormValid = runFormValidation();

    if (isFormValid) {
      setIsCreating(true);
      try {
        const body: CreateMeeting['payload'] = {
          name: meetingDetails.name,
          enrollmentId: meetingDetails.enrollmentId,
          mentorId: meetingDetails.mentorId,
          students: [meetingDetails.students],
          startDate: meetingDetails.startDate,
          agenda: meetingDetails.agenda,
        };

        createMeeting(body);
      } catch (error: any) {
        message.error(error.message);
        setIsCreating(false);
      }
    }
  };

  const runFormValidation = () => {
    setTouched({
      name: true,
      enrollmentId: true,
      mentorId: true,
      students: true,
      startDate: true,
      time: true,
      date: true,
    });

    const isValidLength = (field: string) =>
      (field && field.length > 1) as boolean;

    const newFormValidity = {
      name: isValidLength(meetingDetails.name),
      enrollmentId: isValidLength(meetingDetails.enrollmentId),
      mentorId: isValidLength(meetingDetails.mentorId),
      students: isValidLength(meetingDetails.students),
      startDate: isValidLength(meetingDetails.startDate),
      time: isValidLength(meetingDetails.time),
      date: isValidLength(meetingDetails.startDate),
    };

    setFormValidity(newFormValidity);

    return Object.values(newFormValidity).every(Boolean);
  };

  const runIndividualFormValidation = (field: string, input?: string) => {
    let isValid = true;

    // GENERAL
    if (input === undefined || input.length < 1) {
      isValid = false;
    }

    setFormValidity({
      ...formValidity,
      [field]: isValid,
    });

    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    fieldName?: string,
    inputValue?: string
  ) => {
    event.preventDefault();
    const { name, value } = event.target;

    const field = fieldName === undefined ? name : fieldName;
    const input = value ? value : inputValue;

    runIndividualFormValidation(field, input);
  };

  return (
    <Modal isOpen={visible} onClose={hide}>
      <Modal.Header title="Schedule Meeting" className="mb-6" />
      <Modal.Body>
        {isStudentsLoading ? (
          <div className="my-10">
            <AgoraSpinner fontSize={48} />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col tablet:flex-row justify-between gap-4 tablet:gap-6">
                {/* STUDENT */}
                <AgoraBigSelector
                  label="Student"
                  onSelect={(value) => {
                    if (value !== undefined) {
                      if (value !== meetingDetails.students) {
                        setFormValidity({
                          ...formValidity,
                          enrollmentId: false,
                        });

                        setMeetingDetails({
                          ...meetingDetails,
                          students: value as string,
                          // also reset the enrollmentId in case a previous student & enrollment has been selected
                          // in order to make sure that we do not send an enrolmentId that's invalid for the given student
                          enrollmentId: '',
                        });
                      }
                    }
                  }}
                  value={
                    meetingDetails.students.length
                      ? meetingDetails.students
                      : undefined
                  }
                  options={myStudents}
                  labelKey="fullName"
                  valueKey="_id"
                  onClear={() => {
                    setMeetingDetails({
                      ...meetingDetails,
                      students: '',
                      // also reset the enrollmentId in case a previous student & enrollment has been selected
                      // in order to make sure that we do not send an enrolmentId that's invalid for the given student
                      enrollmentId: '',
                    });
                    setFormValidity({
                      ...formValidity,
                      students: false,
                      enrollmentId: false,
                    });
                  }}
                  onBlur={(e) =>
                    handleBlur(e, 'students', meetingDetails.students)
                  }
                  isRequired
                  isTouched={touched.students}
                  isValid={formValidity.students}
                  errorText="You need to select a student."
                />

                {/* COURSE */}
                <AgoraBigSelector
                  label="Course"
                  onSelect={(value) => {
                    if (value !== undefined)
                      setMeetingDetails({
                        ...meetingDetails,
                        enrollmentId: value as string,
                      });
                  }}
                  value={
                    meetingDetails.enrollmentId.length
                      ? meetingDetails.enrollmentId
                      : undefined
                  }
                  onClear={() => {
                    setMeetingDetails({
                      ...meetingDetails,
                      enrollmentId: '',
                    });
                    setFormValidity({
                      ...formValidity,
                      enrollmentId: false,
                    });
                  }}
                  options={myStudentsData
                    ?.filter((s) => s._id == meetingDetails.students)
                    ?.map((s) => s.enrollment)}
                  labelKey="course"
                  secondLabelKey="title"
                  valueKey="_id"
                  onBlur={(e) =>
                    handleBlur(e, 'enrollmentId', meetingDetails.enrollmentId)
                  }
                  isRequired
                  isTouched={touched.enrollmentId}
                  isValid={formValidity.enrollmentId}
                  errorText="You need to select a course."
                />
              </div>

              {/* DATETIME */}
              <div className="flex flex-col tablet:flex-row justify-between gap-4 tablet:gap-6">
                {/* DATE */}
                <AgoraDatePicker
                  allowClear={false}
                  value={selectedDate}
                  onChange={(value) => setSelectedDate(moment(value))}
                  disabledDate={(date) =>
                    date < moment(moment().format('YYYY-MM-DD'))
                  }
                  className={`agora-date-picker ${
                    formValidity.date && touched.date && 'isValid'
                  }`}
                  onBlur={(e) =>
                    handleBlur(e, 'date', selectedDate?.toString())
                  }
                  isTouched={touched.date}
                  isValid={formValidity.date}
                  errorText="You need to select a date."
                  isRequired
                  label="Date"
                />

                {/* TIME */}
                <AgoraTimePicker
                  time={meetingDetails.time}
                  handleChange={(value: string) => {
                    setMeetingDetails({
                      ...meetingDetails,
                      time: value,
                    });
                  }}
                  handleFocus={() => null}
                  date={selectedDate}
                  disablePastHours={true}
                  onBlur={(e) => handleBlur(e, 'time', meetingDetails.time)}
                  isTouched={touched.time}
                  isValid={formValidity.time}
                  label="Time"
                  errorText="You need to select a time."
                  isRequired
                />
              </div>
            </div>

            {/* AGENDA */}
            <AgoraNewInput
              htmlFor="agenda"
              name="agenda"
              id="agenda"
              type="textarea"
              placeholder="Type in a meeting agenda"
              label="Agenda"
              value={meetingDetails.agenda}
              rows={3}
              onChange={(e) => {
                setMeetingDetails({
                  ...meetingDetails,
                  agenda: e.target.value,
                });
              }}
              onBlur={handleBlur}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="mt-12">
        <PrimaryButtonV3
          style={{ height: 40, width: '100%' }}
          buttonText={
            <div className="flex">
              {isCreating && (
                <AgoraSpinner
                  fontSize={14}
                  color={isCreating ? COLORS.TEXT_PRIMARY : COLORS.BLACK}
                  margin="0 8px 0 0"
                />
              )}

              <span
                style={{
                  color: isCreating ? COLORS.TEXT_PRIMARY : COLORS.BLACK,
                }}
              >
                Schedule Meeting
              </span>
            </div>
          }
          onMouseDown={() => {
            if (!isCreating) {
              submit();
            }
          }}
          disabled={isCreating}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default MeetingModal;
