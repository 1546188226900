import { MeetingStatus, SessionStatus } from '@shared/constants';
import { Meeting } from '@shared/types';
import styles from '@styles/components/table.module.scss';
import moment from 'moment';
import React from 'react';
import { COLORS } from '../../../../v3/global';
import GiveFeedbackButtonMentor from '../../../../views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';

interface Props {
  meeting: Meeting;
  completedNumber?: number;
  isEven: boolean;
}

const SessionHistoryRow: React.FC<Props> = ({ meeting, isEven }) => {
  const classes = `${styles.gridTableRow} ${
    isEven ? styles.gridTableRowEven : styles.gridTableRowOdd
  }`;

  let status = '';
  if (meeting.status === MeetingStatus.SCHEDULED) {
    status = 'Scheduled';
  } else if (meeting.status === MeetingStatus.RESCHEDULED) {
    status = 'Rescheduled';
  } else if (meeting.status === MeetingStatus.CANCELLED) {
    status = 'Cancelled';
  } else if (meeting.status === MeetingStatus.MISSED) {
    status = 'Missed';
  } else if (meeting.status === MeetingStatus.STARTED) {
    status = 'Meeting Started';
  } else if (meeting.status === MeetingStatus.ENDED) {
    status = 'Meeting Ended';
  } else {
    status = 'Other';
  }

  return (
    <>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowFirst}`}
      >
        {meeting.students[0]?.fullName}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {meeting.enrollment?.title}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {moment(meeting.startDate).format('DD MMM YYYY')}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={classes}
      >
        {status}
      </div>
      <div
        style={
          isEven
            ? {
                backgroundColor: COLORS.COMPONENT_BACKGROUND,
                color: COLORS.TEXT_PRIMARY,
              }
            : { color: COLORS.TEXT_PRIMARY }
        }
        className={`${classes} ${styles.gridTableRowLast}`}
      >
        {meeting.status === MeetingStatus.ENDED &&
        meeting.sessionStatus === SessionStatus.NEEDS_FEEDBACK ? (
          <GiveFeedbackButtonMentor meeting={meeting} />
        ) : null}
      </div>
    </>
  );
};

export default SessionHistoryRow;
