import {
  Homework,
  MentorFeedback,
  Meeting,
  MeetingWithHomework,
  StudentFeedback,
} from '../common';
import { ApiDescription, ApiData, RequestsMethod } from '../types';

export const needsFeedback: ApiDescription = {
  endpoint: '/after-meeting/needs-feedback',
  method: RequestsMethod.Get,
};

export type NeedsFeedback = ApiData<
  unknown,
  { meeting: Meeting; as: 'mentor' | 'student' }
>;

export const searchMyMeetings: ApiDescription = {
  endpoint: '/meetings/my',
  method: RequestsMethod.Get,
};
export type SearchMyMeetings = ApiData<
  {
    status: 'all' | 'upcoming' | 'feedback';
    limit?: number;
  },
  MeetingWithHomework[]
>;

export const searchMySessionHistoryMeetings: ApiDescription = {
  endpoint: '/meetings/my-session-history',
  method: RequestsMethod.Get,
};
export type SearchMySessionHistoryMeetings = ApiData<
  {
    limit?: number;
    page?: number;
  },
  { meetings: Meeting[]; total: number; page: number; limit: number }
>;

export const getMeeting: ApiDescription = {
  endpoint: '/meeting/:id',
  method: RequestsMethod.Get,
};
export type GetMeeting = ApiData<unknown, Meeting>;

export const rescheduleMeeting: ApiDescription = {
  endpoint: '/meeting/:id/reschedule',
  method: RequestsMethod.Put,
};

export type RescheduleMeeting = ApiData<Partial<Meeting>, Meeting>;

export const registerMentorFeedback: ApiDescription = {
  endpoint: '/meeting/:id/mentor-feedback',
  method: RequestsMethod.Post,
};
export type RegisterMentorFeedback = ApiData<MentorFeedback, unknown>;

export const registerStudentFeedback: ApiDescription = {
  endpoint: '/meeting/:id/student-feedback',
  method: RequestsMethod.Post,
};
export type RegisterStudentFeedback = ApiData<
  StudentFeedback & { enrollmentId: string },
  unknown
>;

export const markAttended: ApiDescription = {
  endpoint: '/meeting/:id/attended',
  method: RequestsMethod.Post,
};

export type MarkAttended = ApiData<
  {
    userId: string;
    isMentor: boolean;
  },
  unknown
>;

export const createMeeting: ApiDescription = {
  endpoint: '/meeting-new',
  method: RequestsMethod.Post,
};

export type CreateMeeting = ApiData<
  {
    name: string;
    enrollmentId: string;
    mentorId: string;
    students: any;
    startDate: string;
    agenda?: string;
  },
  Meeting
>;

export const deleteMeeting: ApiDescription = {
  endpoint: '/meeting-new/:id',
  method: RequestsMethod.Delete,
};

export type DeleteMeeting = ApiData<unknown, unknown>;

export const updateMeeting: ApiDescription = {
  endpoint: '/meeting-new/:id',
  method: RequestsMethod.Patch,
};

export type UpdateMeeting = ApiData<
  Partial<Meeting> & {
    mentorId?: string;
  },
  { message: string }
>;

export const markMeetingAsEnded: ApiDescription = {
  endpoint: '/meeting-new/mark-as-ended',
  method: RequestsMethod.Patch,
};

export type MarkMeetingAsEnded = ApiData<{ meetingId: string }, Meeting>;

export const getMentorStatisticsByFeedback: ApiDescription = {
  endpoint: '/meeting-new/statistics/:id',
  method: RequestsMethod.Get,
};

export type GetMentorStatisticsByFeedback = ApiData<
  unknown,
  {
    noOfAllMeetings: number;
    noOfFeedbackMeetings: number;
  }
>;

export const noOfCompletedSessionsCurrentMonth: ApiDescription = {
  endpoint: '/meeting-new/mentor/:id/no-of-completed-sessions-current-month',
  method: RequestsMethod.Get,
};

export type NoOfCompletedSessionsCurrentMonth = ApiData<
  unknown,
  {
    noOfSessions: number;
  }
>;

export const searchAllMeetings: ApiDescription = {
  endpoint: '/meeting-new/all',
  method: RequestsMethod.Get,
};

export type SearchAllMeetings = ApiData<
  {
    limit?: number;
    page?: number;
    studentName?: string;
    mentorName?: string;
    status?: number | null;
    rangeStart?: string;
    rangeEnd?: string;
  },
  {
    meetings: Meeting[];
    total: number;
    page: number;
    limit: number;
  }
>;

export const getAllFeedback = {
  endpoint: '/meeting/:id/get-all-feedback',
  method: RequestsMethod.Get,
};

export type GetAllFeedback = ApiData<unknown, MentorFeedback[]>;

export const depricatedEditMeeting = {
  endpoint: '/meeting/:id/edit',
  method: RequestsMethod.Put,
};

export type DepricatedEditMeeting = ApiData<Partial<Meeting>, Meeting>;

export const getOwnData = {
  endpoint: '/meeting/list-own',
  method: RequestsMethod.Get,
};

export type GetOwnData = ApiData<unknown, { meetings: Meeting[] }>;

export const appendStudentNotes = {
  endpoint: '/meeting/:id/append-student-notes',
  method: RequestsMethod.Post,
};

export type AppendStudentNotes = ApiData<{ notes: string }, Meeting>;

export const getMeetingNotes = {
  endpoint: '/meeting/:id/get-notes',
  method: RequestsMethod.Get,
};

export type GetMeetingNotes = ApiData<unknown, { notes: string }>;

export const updateMeetingNotes = {
  endpoint: '/meeting/:id/update-notes',
  method: RequestsMethod.Post,
};

export type UpdateMeetingNotes = ApiData<
  {
    notes: string;
  },
  Meeting
>;
