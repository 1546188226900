import MentorSelector from '@components/V3/MentorSelector';
import SecondaryButton from '@components/V3/Utils/SecondaryButton';
import { useEnrollment, useUpdateEnrollment } from '@shared/react';
import { DatePicker, message, Select } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';

import './enrollments-modal.styles.scss';
import useDetails, {
  DATE_FORMAT,
} from '@modules/Admin/Users/EnrollmentDetails/UpdateEnrollmentModal/useDetails';

type Props = {
  currentEnrolment: string;
  handleCurrentPage: (page: string) => void;
  mentorId: string;
  refetchEnrollments: () => any;
  newEnrolmentTeaching?: boolean;
};

const EditEnrolmentDetailsModal = ({
  currentEnrolment,
  handleCurrentPage,
  mentorId,
  refetchEnrollments,
  newEnrolmentTeaching,
}: Props) => {
  const { startDate, setStartDate, estimatedEndDate, setEstimatedEndDate } =
    useDetails(currentEnrolment ?? '');

  const { data: currentEnrolmentData, refetch } =
    useEnrollment(currentEnrolment);

  const { mutate: updateEnrollment } = useUpdateEnrollment({
    onSuccess: () => {
      message.success('Enrollment updated successfully');
      refetchEnrollments();
      refetch();
    },
  });

  const [status, setStatus] = useState<string | undefined>(
    currentEnrolmentData?.ended === true
      ? 'ended'
      : currentEnrolmentData?.ended === false
      ? 'active'
      : undefined
  );

  const [selectedMentor, setSelectedMentor] = useState<any>({
    choice: {
      label: currentEnrolmentData?.mentor?.fullName ?? 'TBD',
      value: currentEnrolmentData?.mentor?._id ?? 'TBD',
    },
  });
  const [newMentor, setNewMentor] = useState(false);

  const [focused, setFocused] = useState(false);

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}></div>
      <h4
        style={{ color: COLORS.TEXT_SECONDARY, marginBottom: '24px' }}
        className="edit-enrolment-details-section-title"
      >
        Details
      </h4>

      <div style={{ marginBottom: '16px' }}>
        <label
          style={{ color: COLORS.PRIMARY, marginBottom: '8px' }}
          htmlFor="student"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Status
        </label>
        <Select
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder="Select a status"
          options={[
            { label: 'Active', value: 'active' },
            { label: 'Ended', value: 'ended' },
          ]}
          style={{
            cursor: 'pointer',
            width: '100%',
            borderRadius: '6px',
            border: focused
              ? `1px solid ${COLORS.PRIMARY}`
              : `1px solid #FFFFFF`,
          }}
          className="mentor-selector"
          value={status}
          onChange={(selectedValue: string) => {
            setStatus(selectedValue);
          }}
        />
      </div>
      <div style={{ display: 'flex', width: '100%', marginBottom: '16px' }}>
        <div style={{ width: '50%', marginRight: '24px' }}>
          <label
            style={{
              color: COLORS.PRIMARY,
              backgroundColor: 'transparent',
              marginBottom: '8px',
            }}
            htmlFor="location"
            className="block text-sm font-medium leading-6 text-customPrimary"
          >
            Start Date
          </label>
          <DatePicker
            value={moment(startDate)}
            allowClear={false}
            onChange={(date) => setStartDate(moment(date).format(DATE_FORMAT))}
            style={{ backgroundColor: 'transparent', width: '100%' }}
            className="custom-date-picker"
          />
        </div>

        <div style={{ width: '50%' }}>
          <label
            style={{
              color: COLORS.PRIMARY,
              backgroundColor: 'transparent',
              marginBottom: '8px',
            }}
            htmlFor="location"
            className="block text-sm font-medium leading-6 text-customPrimary"
          >
            End Date
          </label>
          <DatePicker
            value={moment(estimatedEndDate)}
            allowClear={false}
            onChange={(date) =>
              setEstimatedEndDate(moment(date).format(DATE_FORMAT))
            }
            style={{
              backgroundColor: 'transparent',
              width: '100%',
            }}
            className="custom-date-picker"
          />
        </div>
      </div>

      <div style={{ marginBottom: '108px' }}>
        <label
          style={{ color: COLORS.PRIMARY, marginBottom: '8px' }}
          htmlFor="mentor"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Mentor
        </label>

        <MentorSelector
          onChange={(data) => {
            setSelectedMentor(data);
            setNewMentor(true);
          }}
          value={selectedMentor}
        />
      </div>

      <SecondaryButton
        onClick={() => {
          updateEnrollment({
            ended: status === 'ended' ? true : false,
            enrollmentId: currentEnrolment,
            estimatedEndDate: moment(estimatedEndDate)?.toISOString(),
            startDate: moment(startDate)?.toISOString(),
            ...(newMentor && { mentorId: selectedMentor?.user?.value }),
          });
        }}
        backgroundColor={COLORS.WHITE}
        height="32px"
        padding="4px 10px 4px 10px"
        margin="12px 0 0 0"
        width="100%"
      >
        <span className="button-text" style={{ color: COLORS.BLACK }}>
          Save
        </span>
      </SecondaryButton>
    </div>
  );
};

export default EditEnrolmentDetailsModal;
