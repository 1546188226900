import MentorPlaceholderAvatar from '@assets/icons/mentor-placeholder-avatar.png';
import USBigPrimary from '@assets/images/us_big_1.jpg';
import USBigPurple from '@assets/images/us_big_2.jpg';
import USBigGold from '@assets/images/us_big_3.jpg';
import { Enrollment } from '@shared/types';
import style from '@styles/views/home.module.scss';
import { Progress } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import useCourseCompleted from '../../../views/UserView/Home/MyProgress/useCourseCompleted';
import './progress-card.styles.scss';

interface Props {
  enrollment: Enrollment;
}

const StudentProgressCard: React.FC<Props> = ({ enrollment }) => {
  const sessionsLength = enrollment.noOfSessions;
  const completedSessions = enrollment.noOfCompletedSessions;

  const courseId = enrollment.course?._id;
  const courseCompletion = useCourseCompleted(courseId as string);

  const { course } = enrollment;

  const [hover, setHover] = useState(false);

  const formatMonthYear = (dateString: string) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(2);

    return `${month} '${year}`;
  };

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        backgroundColor: hover ? COLORS.DARK_GREY : COLORS.COMPONENT_BACKGROUND,
      }}
      className={`${style.enrollment} progressCardComponentContainer`}
    >
      <div
        style={{
          background: `url(${
            course?.headerImage?.['470x320']
              ? course?.headerImage['470x320']
              : course?.title?.toLowerCase()?.includes('coach') ||
                course?.title?.toLowerCase()?.includes('training')
              ? USBigPrimary
              : course?.title?.toLowerCase()?.includes('academic')
              ? USBigPurple
              : USBigGold
          })`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
        className="upperProgressCardContainer"
      >
        <div className="upperProgressCardContent">
          <span className={'dateText'}>{`${
            enrollment?.startDate
              ? formatMonthYear(enrollment.startDate)
              : 'N/A'
          } - ${
            enrollment?.estimatedEndDate
              ? formatMonthYear(enrollment.estimatedEndDate)
              : 'N/A'
          }`}</span>
        </div>
      </div>

      <div
        className={`${style.content} lowerProgressCardContainer`}
        style={{
          backgroundColor: hover
            ? COLORS.DARK_GREY
            : COLORS.COMPONENT_BACKGROUND,
        }}
      >
        <div style={{ marginTop: '14px' }}>
          <div style={{ height: '48px' }}>
            <span
              style={{ color: COLORS.TEXT_PRIMARY }}
              className="courseTitleText"
            >
              {course?.title}
            </span>
          </div>

          <div style={{ marginTop: '4px', marginBottom: '12px' }}>
            <img
              style={{}}
              className="inline-block rounded-full avatar mentorAvatar"
              src={MentorPlaceholderAvatar}
              alt="avatar"
            />
            <span
              style={{ color: COLORS.TEXT_SECONDARY }}
              className="mentorName"
            >
              {enrollment?.mentor?.fullName}
            </span>
          </div>

          <Progress
            strokeColor={course?.color}
            trailColor={COLORS.DARK_GREY2}
            showInfo={false}
            percent={Math.floor(courseCompletion * 100)}
          />
        </div>

        <div className="courseCardDetailsContainer">
          <span
            className={`${style.courseProgressValue} courseCompletitionText`}
            style={{
              color: COLORS.TEXT_SECONDARY,
              marginRight: '4px',
              fontWeight: 'bold',
            }}
          >
            {Math.floor(courseCompletion * 100)
              ? Math.floor(courseCompletion * 100)
              : 0}
            %
          </span>
          <span
            style={{ color: COLORS.TEXT_SECONDARY }}
            className={`${style.courseProgressTitle} courseCompletitionText`}
          >
            Completed
          </span>

          <span
            style={{
              fontSize: '18px',
              marginLeft: '8px',
              marginRight: '8px',
              color: COLORS.TEXT_SECONDARY,
            }}
            className="courseCompletitionText"
          >
            •
          </span>

          <div className="completedSessionsContainer">
            <span
              className={`${style.courseProgressValue} courseCompletitionText`}
              style={{
                color: COLORS.TEXT_SECONDARY,
                marginRight: '4px',
                fontWeight: 'bold',
              }}
            >
              {`${completedSessions}/${sessionsLength}`}
            </span>
            <span
              style={{ color: COLORS.TEXT_SECONDARY }}
              className={`${style.courseProgressTitle} courseCompletitionText`}
            >
              Sessions done
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProgressCard;
