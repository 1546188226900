import Loading from '@components/Common/Loading';
import LockedFormInput from '@components/Form/LockedFormInput';
import { ChevronLeftIcon, PencilIcon } from '@heroicons/react/outline';
import SessionsViewer from '@modules/Admin/Users/EnrollmentDetails/UpdateEnrollmentModal/SessionsViewer';
import ChecklistItem from '@modules/Mentor/MyStudents/StudentDetails/ChecklistItem';
import { CloseModalProps } from '@shared/common';
import {
  useEnrollment,
  useMentorStatistics,
  useUpdateEnrollment,
} from '@shared/react';
import styles from '@styles/components/reusables/modal.module.scss';
import { Modal, message, Tooltip } from 'antd';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import UpdateMentor from './UpdateMentor';
import useDetails, { DATE_FORMAT } from './useDetails';

type Props = CloseModalProps & {
  enrollmentId: string;
  sessionId?: number;
  handleRowTouched?: () => void;
};

const UpdateEnrollmentModal: React.FC<Props> = ({
  visible,
  hide,
  enrollmentId,
  sessionId,
  handleRowTouched,
}) => {
  const {
    data: enrollment,
    isLoading: isEnrollmentLoading,
    refetch: refetchEnrollment,
  } = useEnrollment(enrollmentId);

  const {
    startDate,
    setStartDate,
    estimatedEndDate,
    setEstimatedEndDate,
    findBy,
    setFindBy,
  } = useDetails(enrollmentId);

  // Other state
  const [showUpdateMentor, setShowUpdateMentor] = useState(false);
  const [activeTab, setActiveTab] = useState('Tab 1');

  const tabs = [
    { name: 'Mentor', href: '#', current: false, key: 'Tab 1' },
    { name: 'Sessions', href: '#', current: false, key: 'Tab 2' },
    { name: 'Checklist', href: '#', current: true, key: 'Tab 3' },
  ];

  // Update hooks
  const { mutate: updateEnrollment } = useUpdateEnrollment({
    onSuccess: () => {
      message.success('Enrollment updated successfully');
      refetchEnrollment();
    },
  });

  const { data: statistics } = useMentorStatistics(enrollment?.mentor?._id);

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  useEffect(() => {}, [activeTab]);

  if (isEnrollmentLoading) {
    return <Loading />;
  }

  if (!enrollment) {
    message.error('Enrollment not found!');
    return null;
  }

  const { mentor } = enrollment;

  const mentorStatistics = (
    <>
      <div className={styles.modalInfoRow} style={{ color: 'white' }}>
        <div>No. of total meetings held:</div>
        <div>{statistics?.noOfAllMeetings ?? 0}</div>
      </div>
      <div className={styles.modalInfoRow} style={{ color: 'white' }}>
        <div>No. of meetings with feedback:</div>
        <div>{statistics?.noOfFeedbackMeetings ?? 0}</div>
      </div>
    </>
  );

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        hide();
        handleRowTouched && handleRowTouched();
      }}
      footer={false}
      closable={false}
      width={900}
      style={{ animationDuration: '0s' }}
      bodyStyle={{
        padding: 0,
        minHeight: '90vh',
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
    >
      <div className={styles.modalSecondaryHeader}>
        <ChevronLeftIcon width={25} cursor="pointer" onClick={hide} />
        Update {enrollment?.course?.title} for {enrollment?.user?.fullName}
      </div>

      <div className={styles.modalSecondaryBody}>
        {/* DETAILS FORM SECTION */}
        <div>
          <div
            className={styles.modalSecondarySectionTitle}
            style={{ marginBottom: '10px' }}
          >
            Details
          </div>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div className={styles.modalInfoRow}>
                <div>Start Date:</div>
                <LockedFormInput
                  type="date"
                  value={moment(startDate).format(DATE_FORMAT)}
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    const date = moment(e.target.value);
                    if (date.toString() === 'Invalid date') {
                      return;
                    }
                    setStartDate(date.format(DATE_FORMAT));
                    updateEnrollment({
                      enrollmentId,
                      startDate: moment(e.target.value).toISOString(),
                    });
                  }}
                />
              </div>

              <div className={styles.modalInfoRow}>
                <div>Estimated end Date:</div>
                <LockedFormInput
                  type="date"
                  value={moment(estimatedEndDate).format(DATE_FORMAT)}
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    const date = moment(e.target.value);
                    if (date.toString() === 'Invalid date') {
                      return;
                    }
                    setEstimatedEndDate(date.format(DATE_FORMAT));
                    updateEnrollment({
                      enrollmentId,
                      estimatedEndDate: moment(e.target.value).toISOString(),
                    });
                  }}
                />
              </div>
              <div className={styles.modalInfoRow}>
                <div>Find by:</div>
                <div>{moment(findBy).format(DATE_FORMAT)}</div>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div className={styles.modalInfoRow}>
                <div>Completed meetings:</div>
                <div>
                  {enrollment.noOfCompletedSessions || 0} /{' '}
                  {enrollment.noOfSessions || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END DETAILS FORM SECTION */}

        {/* TABS SECTION */}
        <div className={`mt3 ${styles.modalTabs}`}>
          {/*TAILWIND TABS START*/}
          <div style={{ marginBottom: '16px' }}>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <span
                      style={{
                        cursor: 'pointer',
                        color:
                          activeTab === tab?.key
                            ? COLORS.BLACK
                            : COLORS.TEXT_SECONDARY,
                        borderColor:
                          activeTab === tab?.key ? COLORS.BLACK : 'inherit',
                      }}
                      key={tab?.key}
                      onClick={() => setActiveTab(tab?.key)}
                      className={classNames(
                        tab?.key === activeTab
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium'
                      )}
                      aria-current={tab?.current ? 'page' : undefined}
                    >
                      {tab?.name}
                    </span>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          {/*TAILWIND TABS END*/}

          {activeTab === 'Tab 1' && (
            <div>
              <div className={styles.modalInfoRow}>
                <div>Mentor:</div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {showUpdateMentor && (
                    <UpdateMentor enrollmentId={enrollmentId} />
                  )}
                  {!showUpdateMentor && (mentor?.fullName || 'TBD')}
                  <PencilIcon
                    className="ml1"
                    width={15}
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowUpdateMentor(!showUpdateMentor)}
                  />
                </div>
              </div>

              <div className={styles.modalInfoRow}>
                <div>Statistics:</div>
                <div>
                  <Tooltip title={mentorStatistics}>
                    {statistics && statistics?.noOfAllMeetings > 0
                      ? Math.round(
                          (statistics.noOfFeedbackMeetings /
                            statistics.noOfAllMeetings ?? 1) * 100
                        )
                      : 0}
                    %
                  </Tooltip>
                </div>
              </div>
              <div className={styles.modalInfoRow}>
                <div>Find by:</div>
                <LockedFormInput
                  value={moment(findBy).format('YYYY-MM-DD')}
                  type="date"
                  onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                    setFindBy(moment(e.target.value).format('YYYY-MM-DD'));
                    updateEnrollment({
                      enrollmentId,
                      findBy: moment(e.target.value).toISOString(),
                    });
                  }}
                  style={{ display: 'inline-block' }}
                />
              </div>

              <div className={styles.modalInfoRow}>
                <div>Mentor Found:</div>
                <div>{mentor?.fullName ?? 'N/A'}</div>
              </div>
            </div>
          )}
          {activeTab === 'Tab 2' && (
            <div>
              <SessionsViewer
                sessionId={sessionId?.toString()}
                enrollment={enrollment}
              />
            </div>
          )}

          {activeTab === 'Tab 3' && (
            <div>
              <ChecklistItem isActive={true} enrollment={enrollment} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default UpdateEnrollmentModal;
