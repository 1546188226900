import { enrollmentsApis } from '../../apis';
import {
  genericMutation,
  genericQuery,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useEnrollment = genericQueryWithId<enrollmentsApis.GetEnrollment>(
  enrollmentsApis.getEnrollment
);

export const useEnrollmentMentorHistory =
  genericQueryWithId<enrollmentsApis.GetEnrollmentMentorHistory>(
    enrollmentsApis.getEnrollmentMentorHistory
  );

export const useCreateEnrollment =
  genericMutation<enrollmentsApis.CreateEnrollment>(
    enrollmentsApis.createEnrollment,
    enrollmentsApis.createEnrollment.endpoint
  );

export const useUpdateEnrollment =
  genericMutation<enrollmentsApis.UpdateEnrollment>(
    enrollmentsApis.updateEnrollment,
    enrollmentsApis.createEnrollment.endpoint,
    enrollmentsApis.getEnrollments.endpoint
  );

export const useGetEnrollments =
  genericQueryWithId<enrollmentsApis.GetEnrollments>(
    enrollmentsApis.getEnrollments
  );

export const useUserEnrollments =
  genericQueryWithId<enrollmentsApis.GetUserEnrollments>(
    enrollmentsApis.getUserEnrollments
  );

export const useStudentEnrollments =
  genericQuery<enrollmentsApis.GetStudentEnrollments>(
    enrollmentsApis.getStudentEnrollments
  );

export const useMyMentorEnrollments =
  genericQueryWithParams<enrollmentsApis.SearchMentorEnrollments>(
    enrollmentsApis.searchMentorEnrollments,
    undefined,
    true
  );

export const useMentorNoOfActiveEnrollments =
  genericQueryWithId<enrollmentsApis.GetMentorNoOfActiveEnrollments>(
    enrollmentsApis.getMentorNoOfActiveEnrollments
  );

export const useGetActiveStudentsByMentor =
  genericQuery<enrollmentsApis.GetActiveStudentsByMentor>(
    enrollmentsApis.getActiveStudentsByMentor
  );

export const useAddSession = genericMutation<enrollmentsApis.AddSession>(
  enrollmentsApis.addSession,
  enrollmentsApis.getEnrollment.endpoint
);

export const useDeleteSession = genericMutation<enrollmentsApis.DeleteSession>(
  enrollmentsApis.deleteSession,
  enrollmentsApis.getEnrollment.endpoint
);

export const useEndEnrollment = genericMutation<enrollmentsApis.EndEnrollment>(
  enrollmentsApis.endEnrollment,
  enrollmentsApis.getActiveStudentsByMentor.endpoint
);

export const useResumeEnrollment =
  genericMutation<enrollmentsApis.ResumeEnrollment>(
    enrollmentsApis.resumeEnrollment
  );

export const useGetMentorEnrollmentsDetails =
  genericQueryWithParams<enrollmentsApis.GetMentorEnrollmentsDetails>(
    enrollmentsApis.getMentorEnrollmentsDetails
  );

export const useGetStudentEnrollmentsDetails =
  genericQueryWithParams<enrollmentsApis.GetStudentEnrollmentsDetails>(
    enrollmentsApis.getStudentEnrollmentsDetails
  );

export const useGetMentorTeachingAndEnrolledInCourses =
  genericQueryWithId<enrollmentsApis.GetMentorTeachingAndEnrolledInCourses>(
    enrollmentsApis.getMentorTeachingAndEnrolledInCourses
  );

export const useMinimumMentorCapacity =
  genericQuery<enrollmentsApis.GetMinimumMentorCapacity>(
    enrollmentsApis.getMinimumMentorCapacity
  );

export const useHasMentorUpcomingStudents =
  genericQuery<enrollmentsApis.HasMentorUpcomingStudents>(
    enrollmentsApis.hasMentorUpcomingStudents
  );

export const useUnenrollUser = genericMutation<enrollmentsApis.UnenrollUser>(
  enrollmentsApis.unenrollUser
);
