import Button from '@components/V4/Button';
import Modal from '@components/V4/Modal';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

type EndMeetingModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const EndMeetingModal = (props: EndMeetingModalProps) => {
  const { isOpen, onClose } = props;
  const [isLeavingMeeting, setIsLeavingMeeting] = useState(false);

  const { zoomClient } = useContext(ZoomContext);

  const history = useHistory();

  const leaveMeetingHandler = async () => {
    setIsLeavingMeeting(true);

    await zoomClient?.leave();

    setIsLeavingMeeting(false);
    onClose();
    history.replace('/home');
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="small"
      hasCloseIcon={false}
      className="tablet:py-14 tablet:px-12"
    >
      <Modal.Header
        title="Are you sure you want to 
end the meeting for everyone & give feedback?"
        className=" text-center"
      />
      <Modal.Body className="flex flex-row justify-center gap-4 overflow-x-hidden mt-6">
        <Button
          variant="secondary"
          buttonText="No, Take Me Back"
          onClick={onClose}
        />
        <Button
          variant="primary"
          buttonText="Yes, End Meeting"
          isLoading={isLeavingMeeting}
          onClick={leaveMeetingHandler}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EndMeetingModal;
