import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import AgoraModal, { AgoraModalProps } from '@components/V3/Utils/AgoraModal';
import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import { MEETING_STATUSES, MeetingStatus } from '@shared/constants';
import { useUpdateMeeting } from '@shared/react';
import { Meeting } from '@shared/types';
import { message } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import EditMeetingModalRow from './EditMeetingModalRow';

interface Props extends Omit<AgoraModalProps, 'children'> {
  meeting: Meeting;
  onSave?: () => void;
}

const EditMeetingModal = (props: Props) => {
  const { meeting, onSave, setModalOpen } = props;

  const [status, setStatus] = useState(meeting.status);

  const { mutate: updateMeeting } = useUpdateMeeting(meeting._id, {
    onSuccess: () => {
      message.success('Meeting updated successfully!');
      onSave?.();
    },
  });

  const getRowValue = (value?: string | number, suffix?: string) => {
    if (!value) {
      return '-';
    }

    if (suffix) {
      return `${value}${suffix}`;
    }

    return value;
  };

  const getAttendenceIcon = (attended: boolean) => {
    return attended ? (
      <CheckCircleOutlined className="text-green-50 text-2xl" />
    ) : (
      <CloseCircleOutlined className="text-red-50 text-2xl" />
    );
  };

  const getStatusOptionArray = () => {
    return Object.keys(MEETING_STATUSES).map((statusKey) => ({
      value: parseInt(statusKey),
      label: MEETING_STATUSES[statusKey],
    }));
  };

  const selectStatusHandler = (value?: number | string) => {
    if (value === undefined || value === status) {
      return;
    }

    setStatus(value as MeetingStatus);
    updateMeeting({
      _id: meeting._id,
      status: value as MeetingStatus,
    });
  };

  return (
    <AgoraModal
      isModalOpen={true}
      setModalOpen={setModalOpen}
      title="Edit Meeting"
    >
      <div className="w-full flex flex-col gap-y-4">
        {/* General Section */}
        <div className="flex text-sm font-semibold">General</div>
        <EditMeetingModalRow label="Name" value={meeting.name} />
        <EditMeetingModalRow
          label="Status"
          value={
            <AgoraBigSelector
              className="max-w-64"
              onSelect={selectStatusHandler}
              options={getStatusOptionArray()}
              value={status}
              labelKey="label"
              valueKey="value"
              allowClear={false}
            />
          }
        />
        <EditMeetingModalRow
          label="Date"
          value={moment(meeting.startDate).format('DD MMM YYYY')}
        />

        {/* Mentor Section */}
        <div className="flex text-sm font-semibold mt-6">Mentor</div>
        <EditMeetingModalRow label="Name" value={meeting.mentor.fullName} />
        <EditMeetingModalRow
          className={
            meeting.attended.mentor ? 'text-customGreen' : 'text-customOrange'
          }
          label="Attended"
          value={getAttendenceIcon(meeting.attended.mentor)}
        />
        <EditMeetingModalRow
          label="Homework Rate"
          value={getRowValue(
            meeting.mentorFeedback?.studentHomework?.rate,
            '/5'
          )}
        />
        <EditMeetingModalRow
          label="Homework Explanation"
          value={getRowValue(
            meeting.mentorFeedback?.studentHomework?.explanation
          )}
        />
        <EditMeetingModalRow
          label="Engagement Rate"
          value={getRowValue(
            meeting.mentorFeedback?.studentEngagement?.rate,
            '/5'
          )}
        />
        <EditMeetingModalRow
          label="Engagement Explanation"
          value={getRowValue(
            meeting.mentorFeedback?.studentEngagement?.explanation
          )}
        />
        <EditMeetingModalRow
          label="Progress Rate"
          value={getRowValue(
            meeting.mentorFeedback?.studentProgress?.rate,
            '/5'
          )}
        />
        <EditMeetingModalRow
          label="Progress Explanation"
          value={getRowValue(
            meeting.mentorFeedback?.studentProgress?.explanation
          )}
        />
        <EditMeetingModalRow
          label="Parent help"
          value={getRowValue(meeting.mentorFeedback?.parentHelp)}
        />

        {/* Student Section */}
        <div className="flex text-sm font-semibold mt-6">Student</div>
        <EditMeetingModalRow
          label="Name"
          value={getRowValue(meeting.students?.[0].fullName)}
        />
        <EditMeetingModalRow
          className={
            meeting.attended.student ? 'text-customGreen' : 'text-customOrange'
          }
          label="Attended"
          value={getAttendenceIcon(meeting.attended.student)}
        />
      </div>
    </AgoraModal>
  );
};

export default EditMeetingModal;
