import { ReactComponent as CameraIconActive } from '@assets/icons/video-icons/camera-icon-active.svg';
import { ReactComponent as CameraIcon } from '@assets/icons/video-icons/camera-icon.svg';
import { ReactComponent as EndCallIcon } from '@assets/icons/video-icons/end-call-icon.svg';
import { ReactComponent as HandIcon } from '@assets/icons/video-icons/hand-icon.svg';
import { ReactComponent as MicrophoneIconActive } from '@assets/icons/video-icons/microphone-icon-active.svg';
import { ReactComponent as MicrophoneIcon } from '@assets/icons/video-icons/microphone-icon.svg';

import { ReactComponent as OptionsIcon } from '@assets/icons/video-icons/options-icon.svg';
import { ReactComponent as ShareScreenIcon } from '@assets/icons/video-icons/share-screen-icon.svg';
import { ReactComponent as ThumbsUpIcon } from '@assets/icons/video-icons/thumbs-up-icon.svg';
import IconButton from '@components/V4/IconButton';
import { ZoomContext } from '@modules/MeetingVideo/contexts/ZoomContext';
import { CommandChannel, CommandChannelMsg } from '@zoom/videosdk';
import useToast from 'apps/agora/src/hooks/useToast';
import { mergeClassNames } from 'apps/agora/src/utils/helpers';
import { useContext, useEffect, useRef, useState } from 'react';

interface VideoActionButtonProps {
  isShareScreenDisabled?: boolean;
  setActiveModal: React.Dispatch<
    React.SetStateAction<'end-meeting' | 'settings' | undefined>
  >;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  onCameraButtonClick: () => void;
  onMicrophoneButtonClick: () => void;
  onRaiseHandClick: (senderId: number) => void;
  onThumbsUpClick: (
    senderId: number,
    senderName: string,
    timestamp: number
  ) => void;
  onShareScreenClick: () => Promise<void>;
}

const VideoActionButtons = (props: VideoActionButtonProps) => {
  const {
    isShareScreenDisabled,
    setActiveModal,
    setShowModal,
    onRaiseHandClick,
    onCameraButtonClick,
    onMicrophoneButtonClick,
    onThumbsUpClick,
    onShareScreenClick,
  } = props;

  const { isMicrophoneActive, isCameraActive, zoomClient } =
    useContext(ZoomContext);
  const [isShareScreenActive, setIsShareScreenActive] = useState(false);
  const [isHandUpActive, setIsHandUpActive] = useState(false);

  const commandChannelRef = useRef<typeof CommandChannel>();

  const [showToast] = useToast();

  useEffect(() => {
    if (!zoomClient) return;

    commandChannelRef.current = zoomClient.getCommandClient();

    const passivelyStopShareHandler = () => {
      setIsShareScreenActive(false);
    };

    zoomClient.on('passively-stop-share', passivelyStopShareHandler);

    return () => {
      zoomClient.off('passively-stop-share', passivelyStopShareHandler);
    };
  }, []);

  const handleShowModal = (
    modalPage: 'end-meeting' | 'settings' | undefined
  ) => {
    setShowModal(true);
    setActiveModal(modalPage);
  };

  const handleShareScreenButtonClick = async () => {
    try {
      await onShareScreenClick();
      setIsShareScreenActive((prev) => !prev);
    } catch (error) {
      console.log(error);
    }
  };

  const handleHandButtonClick = async () => {
    if (!commandChannelRef.current || !zoomClient) return;

    try {
      await commandChannelRef.current.send('hand-raise');

      const sessionInfo = zoomClient.getSessionInfo();

      onRaiseHandClick(sessionInfo.userId);
      setIsHandUpActive((prev) => !prev);
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }
  };

  const handleThumbsUpButtonClick = async () => {
    if (!commandChannelRef.current || !zoomClient) return;

    try {
      const command = (await commandChannelRef.current.send(
        'thumbs-up'
      )) as CommandChannelMsg;

      const sessionInfo = zoomClient.getSessionInfo();

      onThumbsUpClick(
        sessionInfo.userId,
        sessionInfo.userName,
        command.timestamp
      );
    } catch (error: any) {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: error.type,
      });
    }
  };

  const handleOptionsButtonClick = () => {
    handleShowModal('settings');
  };

  const handleEndMeetingButton = () => {
    handleShowModal('end-meeting');
  };

  const videoActionButtons = [
    {
      icon: isMicrophoneActive ? <MicrophoneIcon /> : <MicrophoneIconActive />,
      isDanger: true,
      onClickHandler: onMicrophoneButtonClick,
      isActive: !isMicrophoneActive,
    },
    {
      icon: isCameraActive ? <CameraIcon /> : <CameraIconActive />,
      isDanger: true,
      onClickHandler: onCameraButtonClick,
      isActive: !isCameraActive,
    },
    {
      icon: <ShareScreenIcon />,
      onClickHandler: handleShareScreenButtonClick,
      isActive: isShareScreenActive,
      isDisabled: isShareScreenDisabled,
    },
    {
      icon: <HandIcon />,
      onClickHandler: handleHandButtonClick,
      isActive: isHandUpActive,
    },
    {
      icon: <ThumbsUpIcon />,
      onClickHandler: handleThumbsUpButtonClick,
    },
    {
      icon: <OptionsIcon />,
      onClickHandler: handleOptionsButtonClick,
    },
    {
      icon: <EndCallIcon />,
      className:
        'w-14 h-8 bg-customRed text-surfaceHover hover:bg-customRedAccent tablet:w-16 tablet:h-10',
      onClickHandler: handleEndMeetingButton,
    },
  ];

  return (
    <div className="flex gap-2 laptop:gap-6">
      {videoActionButtons.map((button, index) => (
        <IconButton
          key={index}
          icon={button.icon}
          variant="secondary"
          className={mergeClassNames(
            'w-8 h-8 tablet:w-10 tablet:h-10',
            button.className
          )}
          isDisabled={button.isDisabled}
          onClick={button.onClickHandler}
          size="large"
          color={button.isDanger ? 'danger' : 'default'}
          isActive={button.isActive}
        />
      ))}
    </div>
  );
};

export default VideoActionButtons;
