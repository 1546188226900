import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import { ButtonHTMLAttributes, MouseEvent, ReactNode } from 'react';
import { mergeClassNames } from '../../utils/helpers';

type VariantTypes =
  | 'primary'
  | 'primary-black'
  | 'primary-brand'
  | 'secondary'
  | 'secondary-inverted'
  | 'secondary-black'
  | 'text';

type Props = {
  buttonText?: string | ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  disabledIcon?: string | ReactNode;
  className?: string;
  isLoading?: boolean;
  variant?: VariantTypes;
  size?: 'normal' | 'large';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = (props: Props) => {
  const {
    buttonText,
    icon,
    disabled,
    disabledIcon,
    className,
    isLoading = false,
    variant = 'primary',
    size = 'normal',
    ...buttonProps
  } = props;

  const baseButtonStyles = {
    'flex items-center justify-center px-4 h-6 gap-2 rounded whitespace-nowrap':
      variant !== 'text',
    'cursor-not-allowed': disabled,
    'cursor-pointer': !disabled,
  };

  const variantStyles = {
    primary: `bg-[#F5F5F5] text-black stroke-black`,
    'primary-black': 'bg-surfaceBackground text-white stroke-white',
    'primary-brand': 'bg-customPrimary text-black stroke-black',

    secondary: `bg-white bg-opacity-0 text-white stroke-white border border-solid border-white hover:bg-opacity-10`,
    'secondary-inverted':
      'bg-white bg-opacity-0 text-customGrey stroke-customGrey border border-solid border-customGrey hover:bg-opacity-10',
    'secondary-black':
      'bg-black bg-opacity-0 text-black stroke-black border border-solid border-black hover:bg-opacity-10',

    text: ` text-xsm text-white font-raleway font-bold hover:text-customPrimary hover:underline`,
  };

  const disabledStyles = {
    primary: {
      'text-customGrey stroke-customGrey bg-surfaceHover': disabled,
      'hover:bg-white': !disabled,
    },
    'primary-black': {
      'text-customGrey stroke-customGrey bg-surfaceHover': disabled,
      'hover:bg-black': !disabled,
    },
    'primary-brand': {
      'text-customGrey stroke-customGrey bg-surfaceHover': disabled,
      'hover:opacity-80': !disabled,
    },

    secondary: {
      'opacity-25 bg-opacity-10': disabled,
    },
    'secondary-inverted': {
      'opacity-25 bg-opacity-10': disabled,
    },
    'secondary-black': {
      'opacity-25 bg-opacity-10': disabled,
    },

    text: '',
  };

  const sizeStyles = {
    'w-full h-10': size === 'large',
  };

  const componentClass = mergeClassNames(
    baseButtonStyles,
    variantStyles[variant],
    disabledStyles[variant],
    sizeStyles,
    className
  );

  const clickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    if (isLoading) return;

    props.onClick?.(e);
  };

  return (
    <button
      {...buttonProps}
      disabled={disabled}
      type="button"
      onClick={clickHandler}
      className={componentClass}
    >
      {/* ICONS (LOADING ICON OR PROP ICON) */}
      {isLoading ? (
        <LoadingSpinner size={size} inheritColor />
      ) : (
        !!icon && <div className=" stroke-inherit">{icon}</div>
      )}

      {/* BUTTON TEXT */}
      {!!buttonText && <p className="text-inherit text-xxs ">{buttonText}</p>}
    </button>
  );
};

export default Button;
