import './styles/global.less';
import './styles/global.scss';
import './styles/mobile-global.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_AUDIENCE,
  BASE_URL,
  ENVIRONMENT,
  isDevelopment,
  AUTH0_CONNECTION,
} from '@shared/frontendEnv';
import { WebProvider } from '@shared/react';
import axios from 'axios';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { TranslationProvider } from './TranslationContext';

axios.defaults.baseURL = BASE_URL;

// const checkEnv = async () => {
//   const env = await import('@shared/frontendEnv');

//   Object.entries(env).forEach((entry) => {
//     const [key, value] = entry;

//     if (exemptVars.includes(key)) return;
//     if (key !== key.toUpperCase()) return;
//     if (options.onlyPublic && !isPublic(key)) return;
//     if (value) return;

//     console.warn(WARNING_FONT, `Warning, ${key} var is not set in .env`);
//   });
// }

// checkEnv({ onlyPublic: true });

if (ENVIRONMENT === 'production') {
  console.log = function () {};
  console.warn = function () {};
  console.error = function () {};
  console.info = function () {};
  console.debug = function () {};
}

// const theme = localStorage.getItem('theme');

const redirect_uri = `${
  isDevelopment ? 'http://localhost:4200' : BASE_URL
}/home`;

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN ?? ''}
    clientId={AUTH0_CLIENT_ID ?? ''}
    authorizationParams={{
      redirect_uri,
      audience: AUTH0_AUDIENCE ?? '',
      connection: AUTH0_CONNECTION ?? '',
    }}
  >
    <TranslationProvider>
      <WebProvider environment={ENVIRONMENT}>
        <BrowserRouter
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Router />
        </BrowserRouter>
      </WebProvider>
    </TranslationProvider>
  </Auth0Provider>,
  document.getElementById('root')
);
