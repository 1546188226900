import { CloseModalProps } from '@shared/common';
import { useCreateHomework } from '@shared/react';
import { documentUrl } from '@shared/utils/string';
import { Button, Input, message, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import './homework-modal.style.scss';
import { COLORS } from '../../../v3/global';
import styles from '@styles/components/reusables/modal.module.scss';

type Props = CloseModalProps & {
  studentId: string;
  enrollmentId: string;
  meetingId: string;
  postSuccess?: () => void;
  refetch?: () => void;
};

const CreateHomeworkModal: React.FC<Props> = ({
  visible,
  hide,
  studentId,
  enrollmentId,
  meetingId,
  postSuccess,
  refetch,
}) => {
  const {
    mutate: createHomework,
    isLoading,
    isSuccess,
  } = useCreateHomework({
    onSuccess: (homework) => {
      window.open(documentUrl(homework.fileId), '_blank');
      refetch && refetch();
      postSuccess?.();
    },
    onError(err: any) {
      message.error(err?.message || 'Error while creating homework');
    },
  });

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (isSuccess) {
      hide();
    }
  }, [isSuccess]);

  const handleCreate = () => {
    if (!title) {
      message.error('Please provide a title!');
      return;
    }

    createHomework({
      title,
      description,
      student: studentId,
      meeting: meetingId,
      enrollment: enrollmentId,
    });
  };

  return (
    <Modal
      visible={visible}
      footer={
        <div>
          <Button onClick={hide}>Cancel</Button>
          <Button type="primary" loading={isLoading} onClick={handleCreate}>
            Create
          </Button>
        </div>
      }
      onCancel={hide}
      title={<span className={'agoraModalTitle'}>Create Homework</span>}
      bodyStyle={{
        backgroundColor: COLORS.SECTION_BACKGROUND,
      }}
      className={` ${'agora-modal-content'}`}
    >
      <Typography.Text style={{ color: COLORS.TEXT_PRIMARY }}>
        Title
      </Typography.Text>
      <Input value={title} onChange={(e) => setTitle(e.target.value)} />

      <Typography.Text
        style={{
          paddingTop: 10,
          display: 'block',
          color: COLORS.TEXT_PRIMARY,
        }}
      >
        Description
      </Typography.Text>
      <Input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </Modal>
  );
};

export default CreateHomeworkModal;
