import DriveIcon from '@assets/icons/Icon-awesome-google-drive.png';
import NextMeeting from '@components/V3/NextMeeting';
import { MeetingWithHomework } from '@shared/common';
import React from 'react';
import { COLORS } from '../../../../../v3/global';
import JoinButtonStudent from '../../Common/Buttons/JoinButtonStudent';
import MeetingListItemButton from '../../Common/Buttons/MeetingListItemButton';
import './next-meeting-student.styles.scss';

interface Props {
  meeting: MeetingWithHomework;
}

const NextMeetingStudent: React.FC<Props> = ({ meeting }) => {
  const homework = meeting.homework;
  return (
    <NextMeeting meeting={meeting} otherName={meeting.mentor.fullName}>
      <MeetingListItemButton>
        <div
          className="next-meeting-student-homework-button"
          onClick={() => {
            if (homework) {
              window.open(
                `https://docs.google.com/document/d/${homework?.fileId}`,
                '_blank'
              );
            }
          }}
        >
          <img src={DriveIcon} className="next-meeting-student-drive-icon" />

          <span
            className="gradient"
            style={{
              cursor: homework ? 'pointer' : 'not-allowed',
              color: COLORS.TEXT_PRIMARY,
            }}
          >
            Homework
          </span>
        </div>
      </MeetingListItemButton>

      <div className="next-meeting-student-join-button-container">
        <JoinButtonStudent meeting={meeting} />
      </div>
    </NextMeeting>
  );
};

export default NextMeetingStudent;
