import { meetingsApis } from '../../apis';
import {
  genericMutation,
  genericMutationWithId,
  genericQueryWithId,
  genericQueryWithParams,
} from './generics';

export const useRescheduleMeeting =
  genericMutationWithId<meetingsApis.RescheduleMeeting>(
    meetingsApis.rescheduleMeeting,
    meetingsApis.searchMyMeetings.endpoint
  );

export const useMySessionHistoryMeetings =
  genericQueryWithParams<meetingsApis.SearchMySessionHistoryMeetings>(
    meetingsApis.searchMySessionHistoryMeetings
  );

export const useMyMeetings =
  genericQueryWithParams<meetingsApis.SearchMyMeetings>(
    meetingsApis.searchMyMeetings
  );

export const useAllMyMeetings = () => {
  return useMyMeetings(
    { status: 'all', limit: 1000 },
    { refetchInterval: 5000 }
  );
};

export const useFeedbackMeetings = () => {
  return useMyMeetings(
    {
      status: 'feedback',
    },
    { refetchInterval: 5000 }
  );
};

export const useUpcomingMeetings = () => {
  return useMyMeetings(
    {
      status: 'upcoming',
    },
    { refetchInterval: 5000 }
  );
};

export const useMeeting = genericQueryWithId<meetingsApis.GetMeeting>(
  meetingsApis.getMeeting
);

export const useSearchAllMeetings =
  genericQueryWithParams<meetingsApis.SearchAllMeetings>(
    meetingsApis.searchAllMeetings
  );

export const useMarkMeetingAsEnded =
  genericMutation<meetingsApis.MarkMeetingAsEnded>(
    meetingsApis.markMeetingAsEnded
  );

export const useDeleteMeeting =
  genericMutationWithId<meetingsApis.DeleteMeeting>(meetingsApis.deleteMeeting);

export const useRegisterMentorFeedback =
  genericMutationWithId<meetingsApis.RegisterMentorFeedback>(
    meetingsApis.registerMentorFeedback
  );

export const useUpdateMeeting =
  genericMutationWithId<meetingsApis.UpdateMeeting>(
    meetingsApis.updateMeeting,
    meetingsApis.getMeeting.endpoint
  );

export const useCreateMeeting = genericMutation<meetingsApis.CreateMeeting>(
  meetingsApis.createMeeting
);

export const useMentorStatistics =
  genericQueryWithId<meetingsApis.GetMentorStatisticsByFeedback>(
    meetingsApis.getMentorStatisticsByFeedback
  );

export const useMentorSessionNo =
  genericQueryWithId<meetingsApis.NoOfCompletedSessionsCurrentMonth>(
    meetingsApis.noOfCompletedSessionsCurrentMonth
  );
