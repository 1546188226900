import { PageDisplay } from '@components';
import HeaderV3 from '@components/V3/HeaderV3';
import AccountPage from '@modules/Account';
import LessonDisplay from '@modules/Base/LessonDisplay';
import MyStudents from '@modules/Mentor/MyStudents';
import SessionHistory from '@modules/Mentor/SessionHistory';
import KnowledgeBaseAssistant from '@modules/knowledgeBaseAssistant/components/KnowledgeBaseAssistant';
import {
  ACCOUNT,
  HOME,
  COURSES,
  MY_STUDENTS,
  SESSION_HISTORY,
  KNOWLEDGE_BASE_ASSISTANT,
  VIDEO_MEETING,
} from '@routes';
import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState } from '@shared/react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { COLORS } from '../../v3/global';
import Course from './Course';
import ChapterPage from './Course/ChapterPage';
import Courses from './Courses';
import MentorHome from './Home/Mentor';
import Home from './Home/Student';
import MeetingVideo from './MeetingVideo/MeetingVideo';

import './user-view.styles.scss';

const UserView: React.FC = () => {
  const userRole = localStorage.getItem('userRole');

  const { roles } = useAuthState();
  let isMentor: any = undefined;

  if (userRole === 'student') {
    isMentor = false;
  } else if (userRole === 'mentor') {
    isMentor = true;
  } else {
    isMentor = roles.includes(MENTOR_ROLE);
  }

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: COLORS.BLACK,
      }}
    >
      <HeaderV3>
        <div className="user-view-page-content">
          <Switch>
            <Route path={HOME}>{isMentor ? <MentorHome /> : <Home />}</Route>
            <Route path={`${COURSES}/:courseId/chapters/:chapterIndex`}>
              <ChapterPage />
            </Route>
            <Route path={`${COURSES}/:courseId`}>
              <Course />
            </Route>
            <Route path={`${COURSES}`}>
              <div style={{ marginTop: 32 }}>
                <Courses />
              </div>
            </Route>

            {isMentor && (
              <Route path={MY_STUDENTS}>
                <MyStudents />
              </Route>
            )}
            {isMentor && (
              <Route path={SESSION_HISTORY}>
                <SessionHistory />
              </Route>
            )}

            <Route path={`${VIDEO_MEETING}/:meetingId`}>
              <MeetingVideo />
            </Route>

            <Route path={KNOWLEDGE_BASE_ASSISTANT}>
              <KnowledgeBaseAssistant />
            </Route>

            <Route path={ACCOUNT}>
              <AccountPage />
            </Route>

            <Route path="/lesson/:id">
              <PageDisplay />
            </Route>
            <Route path="/lesson">
              <LessonDisplay />
            </Route>

            <Redirect to={HOME} />
          </Switch>
        </div>
      </HeaderV3>
    </div>
  );
};

export default UserView;
