import { MediaDevice } from '@zoom/videosdk';

export const mountDevices = async (stream?: MediaStream) => {
  let devices;
  let localStream = stream;
  const microphones: any = [];
  const cameras: any = [];

  try {
    if (!localStream) {
      localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
    }

    devices = await navigator.mediaDevices.enumerateDevices();

    devices.forEach((device) => {
      if (device.kind === 'audioinput') {
        if (device.deviceId !== 'communications') {
          microphones.push({
            deviceId: device.deviceId,
            label: device.label,
          });
        }
      } else if (device.kind === 'videoinput') {
        cameras.push({
          deviceId: device.deviceId,
          label: device.label,
        });
      }
    });
  } catch (error) {
    console.log({ error });
  }

  return {
    microphones,
    cameras,
    stream: localStream,
  };
};

export const findRemovedDevicesById = (
  oldDevices: MediaDevice[],
  newDevices: MediaDevice[]
) => {
  const newDeviceIds = new Set(newDevices.map((device) => device.deviceId));
  const missingDevice = oldDevices.find(
    (device) => !newDeviceIds.has(device.deviceId)
  );
  return missingDevice ? missingDevice.deviceId : null;
};
